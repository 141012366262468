import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import FeedHeader from "@/components/news/feed/FeedHeader";
import NewsFeedItem from "@/components/news/feed/NewsFeedItem";
import FeedSheets from "@/components/news/shared/FeedSheets";
import useFeedStore from "@/store/useFeedStore";

const NewsFeedsPage = () => {
  const sideBarData = useFeedStore((state) => state.feedSidebarData);

  return (
    <>
      <FeedContentLayout>
        <FeedHeader />
        <main className="overflow-y-auto flex-col items-center p-4 pt-6 mb-4">
          {sideBarData.map((item) => (
            <NewsFeedItem
              key={item?.id}
              feedId={item?.id}
              feedName={item?.name}
            />
          ))}
        </main>
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default NewsFeedsPage;
