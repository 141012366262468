import {
  DiscoveredTagQuery,
  type FilterSideBarCategoryCount,
  FilterSidebarQuery,
  NewsFeedsSearchQuery,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { getPrettyName } from "@/lib/utils/prettyName";
import { format as formatTZ, toZonedTime } from "date-fns-tz";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import { updatePagedFeed, updateTotalCount } from "./feed.actions";
import { FilterCategory } from "./feed.types";
import type { CategoryAppliedFilter } from "./filterSearch.slice";

type PartialFeedTag = {
  tagKind: string;
  tag: string;
  feedArticleIds: number[];
};

async function fetchSearchData(
  searchTerm: string,
): Promise<Record<number, string>> {
  const minLengthForSearch = 3;
  const { feedId, filteredFeedIds } = useFeedStore.getState();

  if (
    !feedId ||
    filteredFeedIds.size === 0 ||
    !searchTerm ||
    searchTerm?.length < minLengthForSearch
  )
    return {};

  const response = await client
    .query(NewsFeedsSearchQuery, {
      feedId,
      searchTerm,
      ids: Array.from(filteredFeedIds),
    })
    .toPromise();

  if (!response.data) {
    console.error("Issue with GraphQL response");
    return {};
  }

  return response.data.feedSearch.items.reduce(
    (acc, item) => {
      const { id, field } = item;
      const firstContent = field[0]?.content[0];
      if (!firstContent) return acc;
      acc[id] = firstContent;
      return acc;
    },
    {} as Record<number, string>,
  );
}

const mapToFilterFeedIdSearchResults = (
  foundSearchHits: Record<number, string>,
): [number, searchHit: { mention: string; term: string }][] => {
  if (!foundSearchHits) return [];

  const results: [number, searchHit: { mention: string; term: string }][] = [];

  for (const itemId in foundSearchHits) {
    const searchHitString = foundSearchHits[+itemId];

    if (!searchHitString) continue;

    const emRegex = /<em><b>(.*?)<\/b><\/em>/g;
    const match = searchHitString.match(emRegex);
    const mention = searchHitString.replace(emRegex, "$1");
    const term = match ? match[0].replace(/<.*?>/g, "") : "";

    results.push([+itemId, { mention, term }]);
  }

  return results;
};

export const filterBySearchTerms = async (searchParam?: string) => {
  const searchTerms =
    searchParam ?? useFeedParamsStore.getState().searchTerms ?? "";
  const shouldSearch = searchTerms.length !== 0;

  // Reset feed state before new search
  useFeedStore.setState(
    {
      fetchingSearch: shouldSearch,
      filterFeedIdSearch: [], // Clear existing filtered results
    },
    false,
    "[EFFECT]: reset feed state for search",
  );

  useFeedParamsStore.setState(
    { searchTerms },
    false,
    "[USER]: updated search terms",
  );

  if (!shouldSearch) {
    updatePagedFeed();
    return;
  }

  const searchResults = await fetchSearchData(searchTerms);
  const filterFeedIdSearch = mapToFilterFeedIdSearchResults(searchResults);

  useFeedStore.setState(
    {
      fetchingSearch: false,
      filterFeedIdSearch,
    },
    false,
    "[EFFECT]: Update search results",
  );

  updatePagedFeed();
};

// Helper function to initialize category structure
const initializeCategoryStructure = (
  origFilters: CategoryAppliedFilter,
): CategoryAppliedFilter => {
  const categoryAppliedFilters: CategoryAppliedFilter = {};

  for (const [category, filter] of Object.entries(origFilters)) {
    categoryAppliedFilters[category] = {
      itemCounts: {},
      ...(filter.name && { name: filter.name }),
      sorted: filter.sorted ?? true,
    };

    if (filter.itemCounts) {
      for (const [tag, set] of Object.entries(filter.itemCounts)) {
        if (!categoryAppliedFilters[category].itemCounts) {
          categoryAppliedFilters[category].itemCounts = {};
        }
        categoryAppliedFilters[category].itemCounts[tag] = new Set(set);
      }
    }
  }

  return categoryAppliedFilters;
};

export const setCountsForThemes = (tags: PartialFeedTag[]) => {
  const origFilters = useFeedStore.getState().categoryAppliedFilters;
  const categoryAppliedFilters = initializeCategoryStructure(origFilters);

  for (const tag of tags) {
    const tagKind = getPrettyName(tag.tagKind);

    if (!categoryAppliedFilters[tagKind]) {
      categoryAppliedFilters[tagKind] = {
        itemCounts: {},
        sorted: origFilters[tagKind]?.sorted ?? true,
        ...(origFilters[tagKind]?.name && { name: origFilters[tagKind].name }),
      };
    }

    if (!categoryAppliedFilters[tagKind].itemCounts) {
      categoryAppliedFilters[tagKind].itemCounts = {};
    }

    const currentSet =
      categoryAppliedFilters[tagKind].itemCounts[tag.tag] ?? new Set<number>();
    if (tag.feedArticleIds) {
      for (const id of tag.feedArticleIds) {
        currentSet.add(id);
      }
    }
    categoryAppliedFilters[tagKind].itemCounts[tag.tag] = currentSet;
  }

  return categoryAppliedFilters;
};

export const setCounts = (categoryCounts: FilterSideBarCategoryCount[]) => {
  const origFilters = useFeedStore.getState().categoryAppliedFilters;
  const categoryAppliedFilters = initializeCategoryStructure(origFilters);

  for (const { category, items } of categoryCounts) {
    // Only process if we have items to add
    if (items.length > 0) {
      // Initialize category if it doesn't exist, preserving sorted from original state
      if (!categoryAppliedFilters[category]) {
        categoryAppliedFilters[category] = {
          itemCounts: {},
          sorted: origFilters[category]?.sorted ?? true,
          ...(origFilters[category]?.name && {
            name: origFilters[category].name,
          }),
        };
      }

      // Ensure itemCounts exists
      if (!categoryAppliedFilters[category].itemCounts) {
        categoryAppliedFilters[category].itemCounts = {};
      }

      // Process items
      for (const item of items) {
        if (
          item.label === undefined ||
          item.label === null ||
          item.label === ""
        )
          continue;
        categoryAppliedFilters[category].itemCounts[item.label] =
          new Set<number>(item.ids || []);
      }
    }
  }

  return categoryAppliedFilters;
};

export const updateFilteredIds = () => {
  const { allFeedIds, categoryAppliedFilters } = useFeedStore.getState();
  const { selected } = useFeedParamsStore.getState();
  if (allFeedIds.size === 0) return allFeedIds;
  let filteredFeedIds = new Set<number>(allFeedIds);
  for (const selectedKey in selected) {
    const selectedItems = selected[selectedKey];
    const itemCountsMap = categoryAppliedFilters[selectedKey]?.itemCounts;
    if (!itemCountsMap || !selectedItems) continue;

    let currentFilterGroupSet = new Set<number>();

    // Return empty set if any selected item has no ids
    for (const selectedItem of selectedItems) {
      const itemIds = itemCountsMap[selectedItem];
      // if (!itemIds?.length) return new Set<number>();

      currentFilterGroupSet = currentFilterGroupSet.union(new Set(itemIds));
    }

    if (currentFilterGroupSet.size === 0) continue;
    filteredFeedIds = filteredFeedIds.intersection(currentFilterGroupSet);
    if (filteredFeedIds.size === 0) break;
  }

  useFeedStore.setState(
    {
      filteredFeedIds,
    },
    false,
    "[Effect]: Update filtered feed ids",
  );

  updateTotalCount();
  filterBySearchTerms();
};

export const fetchFilters = async (feedId: number) => {
  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  const filters = client.query(DiscoveredTagQuery, {
    feedId,
    startDate,
    endDate,
  });

  const $filters = filters.subscribe((data) => {
    const categoryAppliedFilters = setCountsForThemes(
      data.data?.feedArticleTagsByFeed ?? [],
    );
    useFeedStore.setState(
      {
        categoryAppliedFilters,
      },
      false,
      "[Effect] Tags: Updated discovered items",
    );
  });

  useFeedStore.setState(
    {
      unsubscribeFetchFilters: $filters.unsubscribe,
    },
    false,
    "Update unsubscribe for precompute filters",
  );

  const filterSidebarCounts = client.query(FilterSidebarQuery, {
    feedId: feedId,
    startDate: startDate,
    endDate: endDate,
  });

  const $filterSidebarCounts = filterSidebarCounts.subscribe((data) => {
    if (data.error) {
      console.error("Error fetching filter sidebar counts:", data.error);
      useFeedStore.setState(
        {
          allFeedIds: new Set<number>(),
          fetchingFilters: false,
        },
        false,
        "[API] Filters: Failed to fetch",
      );
    }

    const precomputeCounts = data?.data?.precomputeCounts;
    if (!precomputeCounts) return;

    const categoryAppliedFilters = setCounts(precomputeCounts.counts);
    const categoryFilterGroup = categoryAppliedFilters[FilterCategory.CATEGORY];
    let allFeedIds = new Set<number>();
    for (const categoryFilter in categoryFilterGroup?.itemCounts) {
      const items = categoryFilterGroup?.itemCounts[categoryFilter];
      if (!items) continue;
      allFeedIds = allFeedIds.union(items);
    }
    useFeedStore.setState(
      {
        allFeedIds,
        categoryAppliedFilters,
        fetchingFilters: false,
      },
      false,
      "[Effect] Filters: Updated sidebar counts",
    );

    updateTotalCount();
    updateFilteredIds();
  });

  useFeedStore.setState(
    {
      unsubscribeFetchDynamicFilters: $filterSidebarCounts.unsubscribe,
    },
    false,
    "Update unsubscribe for dynamic filters",
  );
};

export const isFeedFiltered = (): boolean => {
  return (
    useFeedParamsStore.getState().searchTerms?.length > 0 ||
    Object.values(useFeedParamsStore.getState().selected)?.some(
      (selected) => selected ?? []?.length > 0,
    )
  );
};

export const getFormattedDates = (
  startDate?: Date,
  endDate?: Date,
  filterDays?: number,
) => {
  const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const timeZone = "UTC";

  if (!startDate && !filterDays) {
    const endDate = toZonedTime(new Date(), timeZone);
    const startDate = toZonedTime(new Date(), timeZone);
    startDate.setDate(startDate.getDate() - 30);

    return {
      startDateFormatted: startDate.toISOString(),
      endDateFormatted: endDate.toISOString(),
    };
  }

  const startDateFormatted = startDate
    ? formatTZ(toZonedTime(startDate, timeZone), "yyyy-MM-dd", { timeZone })
    : formatTZ(
        toZonedTime(
          new Date(Date.now() - (filterDays ?? 30) * MILLISECONDS_IN_A_DAY),
          timeZone,
        ),
        "yyyy-MM-dd",
        { timeZone },
      );

  const endDateFormatted = endDate
    ? formatTZ(toZonedTime(endDate, timeZone), "yyyy-MM-dd", { timeZone })
    : formatTZ(toZonedTime(new Date(), timeZone), "yyyy-MM-dd", { timeZone });

  return {
    startDateFormatted,
    endDateFormatted,
  };
};

export function getDateParameters() {
  const { filterDays, startDate, endDate, isCustomDaysRange } =
    useFeedParamsStore.getState();
  const feedVolumeSelectedDate = useFeedStore.getState().feedVolumeSelectedDate;

  const dayFilter =
    isCustomDaysRange && startDate && endDate ? undefined : filterDays;

  const { startDateFormatted, endDateFormatted } = getFormattedDates(
    feedVolumeSelectedDate
      ? new Date(feedVolumeSelectedDate.startDate)
      : startDate,
    feedVolumeSelectedDate ? new Date(feedVolumeSelectedDate.endDate) : endDate,
    filterDays,
  );

  return { startDateFormatted, endDateFormatted, dayFilter };
}
