import store from "@/store/store";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import AnalyticsProvider from "./lib/analytics";
import { FeatureManagementProvider } from "./lib/feature-management/FeatureManagementProvider";
import UrqlProvider from "./lib/urqlProvider.tsx";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const Providers = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <AnalyticsProvider>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
          <FeatureManagementProvider>
            <UrqlProvider>{children}</UrqlProvider>
          </FeatureManagementProvider>
        </GoogleOAuthProvider>
      </AnalyticsProvider>
    </Provider>
  );
};

export default Providers;
