import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const FeedLoadingIndicator = () => {
  return (
    <div className="w-full">
      <div className="h-12 mx-auto">
        <DotLottieReact src="/loader.lottie" loop autoplay />
      </div>
      <div className="text-gray-500 text-center text-xs -mt-1">
        More articles loading...
      </div>
    </div>
  );
};

export default FeedLoadingIndicator;
