import toast from "@/components/ui/toast";
import { FeedArticlesQuery, TotalCountQuery } from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import { fetchFilters, getDateParameters } from "./filterSearch.actions";
import {
  fetchTierConfiguration,
  getSortParams,
  handleFeedItems,
  mapNodeToFeedItem,
} from "./shared";

const ARTICLES_PER_PAGE = 6;

export function resetFeed() {
  useFeedStore.setState(
    {
      feedId: undefined,
      hasNextPage: false,
      cursor: null,
      feedItems: [],
      createOrEditFeedState: "initial",
      fetchingFilters: true,
      fetchingFeed: true,
      filteredFeedIds: new Set<number>(),
      allFeedIds: new Set<number>(),
      removalSet: new Set<number>(),
      filterFeedIdSearch: [],
    },
    false,
    "[User] Feed: Selected overview",
  );
}

export function setFeedId(feedId: number | null) {
  const { feedId: existingFeed } = useFeedStore.getState();
  const feedChanged = feedId !== existingFeed;

  const { unsubscribeFetchFilters, unsubscribeFetchDynamicFilters } =
    useFeedStore.getState();

  if (!feedId || feedChanged) {
    unsubscribeFetchFilters?.();
    unsubscribeFetchDynamicFilters?.();
  }

  if (feedChanged) {
    const categoryAppliedFilters =
      useFeedStore.getInitialState().categoryAppliedFilters;
    useFeedStore.setState(
      {
        feedId,
        categoryAppliedFilters,
        filteredFeedIds: new Set<number>(),
        allFeedIds: new Set<number>(),
        removalSet: new Set<number>(),
        filterFeedIdSearch: [],
        feedItems: [],
        fetchingFilters: true,
        createOrEditFeedState: "initial",
        fetchingFeed: true,
        hasNextPage: false,
        cursor: null,
      },
      false,
      `[User] Feed: Selected #${feedId}`,
    );
    useFeedParamsStore.setState(
      { searchTerms: "" },
      false,
      "[Effect]: Reset search terms",
    );
  }

  if (!feedId) return;

  useFeedStore.setState(
    {
      currentCount: undefined,
      totalCount: undefined,
    },
    false,
    "[Effect] Feed: Set to paged mode",
  );

  fetchFilters(feedId);
}

export const updatePagedFeed = () => {
  const { feedId } = useFeedStore.getState();

  if (!feedId) return;

  useFeedStore.setState(
    {
      feedId,
      hasNextPage: false,
      cursor: null,
      feedItems: [],
      currentCount: undefined,
      fetchingFeed: true,
      continueFetchingFeed: false,
    },
    false,
    "[User] Feed: updated filters for page feed refetch",
  );

  fetchFeed(feedId);
};

const mapScoreRangeToCustomScore = (rangeStr: string) => {
  const parts = rangeStr.split(" to ");
  if (parts.length !== 2) {
    throw new Error(`Invalid range format: ${rangeStr}`);
  }

  const start = Number(parts[0]);
  const end = Number(parts[1]);

  if (Number.isNaN(start) || Number.isNaN(end)) {
    throw new Error(`Invalid numbers in range: ${rangeStr}`);
  }

  return {
    customScore: {
      start: Math.min(start, end),
      end: Math.max(start, end),
    },
  };
};

export const buildScoreFilter = (selected: string[]) => {
  if (!selected?.length) return null;
  const firstItem = selected[0];
  return firstItem ? mapScoreRangeToCustomScore(firstItem) : null;
};

let currentFeedController: AbortController | null = null;

export const fetchFeed = async (feedId: number) => {
  // Cancel any ongoing requests
  if (currentFeedController) {
    currentFeedController.abort();
  }
  currentFeedController = new AbortController();
  const controller = currentFeedController;

  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  const sortOrder = useFeedParamsStore.getState().sortOrder;

  const { scoreSort: score, dateSort: datePublished } =
    getSortParams(sortOrder);
  const ids = getIdsForPaged();

  try {
    const [feedResult, tierResult] = await Promise.all([
      client
        .query(
          FeedArticlesQuery,
          {
            feedId,
            startDate,
            endDate,
            after: "",
            first: ARTICLES_PER_PAGE,
            score,
            datePublished,
            ids,
          },
          { fetchOptions: { signal: controller.signal } },
        )
        .toPromise(),
      fetchTierConfiguration(),
    ]);

    // Check if this request was aborted or if feed changed
    if (
      controller.signal.aborted ||
      feedId !== useFeedStore.getState().feedId
    ) {
      return;
    }

    if (!feedResult.data?.feedArticles) return;
    if (!tierResult.data) {
      toast.error("Failed to load tier configuration data");
      return;
    }

    const { edges, pageInfo, totalCount } = feedResult.data.feedArticles;
    const feedItems = edges?.map(({ node }) => mapNodeToFeedItem(node)) ?? [];

    handleFeedItems(feedItems, tierResult.data, feedId, pageInfo, totalCount);
  } catch (error: unknown) {
    // Ignore aborted request errors
    if (error instanceof Error && error.name === "AbortError") return;

    console.error("Error processing feed data:", error);
    toast.error("Failed to process feed data");
  }
};

export const fetchNextPage = async () => {
  const { feedId, cursor, fetchingFeed, continueFetchingFeed } =
    useFeedStore.getState();
  const { sortOrder } = useFeedParamsStore.getState();

  if (!feedId || fetchingFeed || continueFetchingFeed || !cursor) return;

  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  useFeedStore.setState(
    { continueFetchingFeed: true },
    false,
    "[User] Feed: Scrolled to fetch next page",
  );

  try {
    const { scoreSort: score, dateSort: datePublished } =
      getSortParams(sortOrder);
    const ids = getIdsForPaged();

    const feedItemsQuery = client.query(FeedArticlesQuery, {
      feedId,
      startDate,
      endDate,
      after: cursor,
      first: ARTICLES_PER_PAGE,
      score,
      datePublished,
      ids,
    });

    const [tierResult, feedResult] = await Promise.all([
      fetchTierConfiguration(),
      feedItemsQuery.toPromise(),
    ]);

    if (!feedResult.data?.feedArticles) return;
    if (!tierResult.data) {
      toast.error("Failed to retrieve tier configuration.");
      return;
    }

    const { edges, pageInfo, totalCount } = feedResult.data.feedArticles;
    const newItems = edges?.map(({ node }) => mapNodeToFeedItem(node)) ?? [];

    handleFeedItems(
      newItems,
      tierResult.data,
      feedId,
      pageInfo,
      totalCount,
      true,
    );

    useFeedStore.setState(
      { continueFetchingFeed: false },
      false,
      "[API] Feed: Loading next items",
    );
  } catch (error) {
    console.error("Error fetching next page:", error);
    useFeedStore.setState(
      { continueFetchingFeed: false },
      false,
      "[System] Feed: Next page failed",
    );
  }
};

export const updateDateRange = (
  days?: number,
  startDate?: Date,
  endDate?: Date,
  isCustomDaysRange?: boolean,
) => {
  console.log("updated date range");
  useFeedParamsStore.setState(
    {
      filterDays: days,
      startDate: startDate,
      endDate: endDate,
      isCustomDaysRange,
    },
    false,
    "[User] Date: Updated range",
  );

  setFeedId(useFeedStore.getState().feedId);
  updateTotalCount();
};

export const setFeedVolumeSelectedDate = (
  dateRange: { startDate: string; endDate: string } | null,
) => {
  useFeedStore.setState(
    {
      feedVolumeSelectedDate: dateRange,
    },
    false,
    "[User] Feed: Updated volume date",
  );

  updatePagedFeed();
};

export const setPeriod = (period: "day" | "week" | "month" | null) =>
  useFeedParamsStore.setState({ period }, false, "[User] Period: Updated");

function getIdsForPaged() {
  const { filteredFeedIds, filterFeedIdSearch } = useFeedStore.getState();
  const { searchTerms } = useFeedParamsStore.getState();

  const ids =
    searchTerms.length > 0
      ? filterFeedIdSearch.map(([id]) => id)
      : Array.from(filteredFeedIds);
  return ids;
}

let currentCountController: AbortController | null = null;

export async function updateTotalCount() {
  const { feedId } = useFeedStore.getState();

  if (!feedId) return;

  // Cancel any ongoing count requests
  if (currentCountController) {
    currentCountController.abort();
  }
  currentCountController = new AbortController();
  const controller = currentCountController;

  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  try {
    const result = await client
      .query(
        TotalCountQuery,
        {
          startDate,
          endDate,
          feedId,
        },
        { fetchOptions: { signal: controller.signal } },
      )
      .toPromise();

    // Check if this request was aborted or if feed changed
    if (
      controller.signal.aborted ||
      feedId !== useFeedStore.getState().feedId
    ) {
      return;
    }

    if (result.error) {
      console.error("Error fetching total count:", result.error);
      return;
    }

    if (!result.data?.feedArticlesAggregate?.total) {
      console.error("Did not resolve total count data.");
      return;
    }

    const newTotalCount = result.data.feedArticlesAggregate.total;
    const currentState = useFeedStore.getState();

    if (currentState.totalCount !== newTotalCount) {
      useFeedStore.setState(
        {
          totalCount: newTotalCount,
        },
        false,
        "[API]: Updated Total Count",
      );
    }
  } catch (error: unknown) {
    // Ignore aborted request errors
    if (error instanceof Error && error.name === "AbortError") return;

    console.error("Error fetching total count:", error);
  }
}
