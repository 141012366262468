import { graphql } from "@/data-access/graphql";
import type { ChartColorInput } from "@/lib/utils/chartUtils";
import { FilterCategory } from "@/store/news/feed.types";
import { buildScoreFilter } from "@/store/news/shared.ts";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { colorKeysByStackedBy } from "./volumeHelpers";

export const GetFeedVolumeQueryAndStackByLabels = graphql(`
  query GetFeedVolume(
    $input: VolumeDataInput!
    $filters: FeedArticleFilter!
    $enum: String!
  ) {
    feedVolumeData(input: $input, filters: $filters) {
      startOfPeriod
      endOfPeriod
      volume
      stackedLabels
      stackedValues
    }
    enumLabels(enum: $enum)
  }
`);

type GetFeedVolumeVariablesType = Parameters<
  Exclude<typeof GetFeedVolumeQueryAndStackByLabels.__apiType, undefined>
>[0];

export type FeedVolumeData = NonNullable<
  Awaited<
    ReturnType<
      Exclude<
        (typeof GetFeedVolumeQueryAndStackByLabels)["__apiType"],
        undefined
      >
    >
  >["feedVolumeData"]
>[number];

export const useGetFeedVolume = () => {
  // url params
  const { feedId } = useParams();

  const [stackFeedVolumeBy, filteredIds] = useFeedStore((state) => [
    state.stackFeedVolumeBy,
    state.filteredFeedIds,
  ]);

  const [endDate, filterDays, startDate, isCustomDaysRange, period, selected] =
    useFeedParamsStore((state) => [
      state.endDate,
      state.filterDays,
      state.startDate,
      state.isCustomDaysRange,
      state.period,
      state.selected,
    ]);

  const startDateFormatted = startDate ? format(startDate, "yyyy-MM-dd") : null;
  const endDateFormatted = endDate ? format(endDate, "yyyy-MM-dd") : null;

  const input: GetFeedVolumeVariablesType["input"] = {
    period,
    daysSince: isCustomDaysRange ? null : filterDays,
    stackBy: stackFeedVolumeBy,
  };

  const filters: GetFeedVolumeVariablesType["filters"] = {
    // biome-ignore lint/style/noNonNullAssertion: <feedId won't be null>
    feedId: Number.parseInt(feedId!),
    dateRange: {
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    },
    ids: Array.from(filteredIds),
    excluded: false,
    score: buildScoreFilter(selected[FilterCategory.IMPACT_SCORE] || []),
  };

  const [result] = useQuery({
    query: GetFeedVolumeQueryAndStackByLabels,
    variables: { input, filters, enum: stackFeedVolumeBy ?? "" },
  });

  const { data, fetching } = result;

  return {
    feedVolume: transformFeedVolumeData(data?.feedVolumeData ?? []),
    fetching,
    stackByLabels: (data?.enumLabels as string[]) ?? [],
  };
};

export type Period = "day" | "week" | "month" | null;

type FormatterOptions = {
  startDate: string | undefined | number;
  endDate: string | undefined | number;
};

export const getFormatterForPeriod = (
  period: Period,
  { startDate, endDate }: FormatterOptions,
): string => {
  const normalizeDate = (
    date: string | number | undefined,
  ): string | undefined => {
    if (typeof date === "number") {
      return new Date(date).toISOString();
    }
    return date;
  };

  // Normalize startDate, endDate
  const normalizedStartDate = normalizeDate(startDate);
  const normalizedEndDate = normalizeDate(endDate);

  if (period && normalizedStartDate && normalizedEndDate) {
    const parseDate = (date: string) => toZonedTime(parseISO(date), "UTC");
    if (period === "day") {
      return format(parseDate(normalizedStartDate), "MMM dd, yyyy");
    }

    if (period === "week") {
      const start = format(parseDate(normalizedStartDate), "MMM d");
      const end = format(parseDate(normalizedEndDate), "d, yyyy");
      return `${start}-${end}`;
    }

    if (period === "month") {
      return format(parseDate(normalizedStartDate), "MMM yyyy");
    }
  }

  return "";
};

/**
 * Transforms feed volume data into recharts acceptable format.
 *
 * @param {FeedVolumeData[]} data - An array of feed volume data objects to transform.
 *
 * @returns {Array<Record<string, string | number>>} A new array of transformed data objects. Each object contains the `startOfPeriod`, `endOfPeriod`, and `volume` properties.
 * Additionally, keys derived from `stackedLabels` are capitalized, replacing underscores with spaces, and their corresponding values are added from `stackedValues`.
 *
 * @example
 * // Input data
 * const data = [
 *   {
 *     startOfPeriod: "2024-01-01",
 *     endOfPeriod: "2024-01-31",
 *     stackedLabels: ["content_category", "news"],
 *     stackedValues: [100, 200],
 *     volume: 500,
 *   },
 * ];
 *
 * // Result
 * const result = transformFeedVolumeData(data);
 * console.log(result);
 * // Output:
 * // [
 * //   {
 * //     startOfPeriod: "2024-01-01",
 * //     endOfPeriod: "2024-01-31",
 * //     volume: 500,
 * //     "Content Category": 100,
 * //     "News": 200,
 * //   },
 * // ]
 */
export const transformFeedVolumeData = (
  data: FeedVolumeData[],
): Array<Record<string, string | number>> => {
  return data.map((item) => {
    const { startOfPeriod, endOfPeriod, stackedLabels, stackedValues, volume } =
      item;

    const transformedItem: Record<string, string | number> = {
      startOfPeriod,
      endOfPeriod,
      volume,
    };

    if (stackedLabels && stackedValues) {
      stackedLabels.forEach((option: string, index: number): void => {
        const value = stackedValues[index];
        if (value !== undefined) {
          const transformedLabel = /^\d|[-+]/.test(option)
            ? option
            : option
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                )
                .join(" ");
          transformedItem[transformedLabel] = value;
        }
      });
    }

    return transformedItem;
  });
};

export type StackByKey = keyof typeof colorKeysByStackedBy | null;

export function getColorsForStackBy(
  stackBy: StackByKey,
): ChartColorInput[] | undefined {
  if (!stackBy) return ["violet"] as ChartColorInput[];

  if (
    ["readership", "domain_authority", "social", "content_category"].includes(
      stackBy,
    )
  ) {
    return colorKeysByStackedBy.social as ChartColorInput[];
  }

  if (Object.prototype.hasOwnProperty.call(colorKeysByStackedBy, stackBy)) {
    return colorKeysByStackedBy[stackBy] as ChartColorInput[];
  }

  return ["violet"] as ChartColorInput[];
}
