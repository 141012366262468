import type { AuthorType } from "@/types/article";
import { User, X } from "@phosphor-icons/react";
import { useState } from "react";
import type React from "react";
import EditableField from "../shared/EditableField";

interface ArticleAuthorProps {
  articleAuthors?: AuthorType[];
  overriddenArticleAuthors?: AuthorType[];
  onAuthorChange?: (author: string | null) => void;
}

const ArticleAuthor: React.FC<ArticleAuthorProps> = ({
  articleAuthors,
  overriddenArticleAuthors,
  onAuthorChange,
}) => {
  const authors = overriddenArticleAuthors ?? articleAuthors ?? [];
  const authorNames = authors.map((author) => author.name).join(", ");
  const [editedAuthorName, setEditedAuthorName] = useState(authorNames);

  const handleAuthorSave = (editedAuthor: string) => {
    onAuthorChange?.(editedAuthor);
    setEditedAuthorName(editedAuthor);
    return true;
  };

  return (
    <EditableField
      fieldName="Author"
      initialFieldValue={editedAuthorName}
      onSave={handleAuthorSave}
      className="pl-7 pr-8 text-xs h-7 w-full border border-transparent"
      placeholderText="Author N/A"
      LeftIcon={User}
      ClearIcon={X}
    />
  );
};

export default ArticleAuthor;
