import { isProduction } from "@/config";
export { default as useFeature } from "./useFeature";
import { GrowthBook } from "@growthbook/growthbook-react";
import type { AppFeatures } from "./generated-types/app-features";

export const gb = new GrowthBook<AppFeatures>({
  apiHost: "https://cdn.growthbook.io",
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction,
  trackingCallback: (experiment, result) => {
    if (typeof window?.gtag === "function") {
      window.gtag("event", "experiment_viewed", {
        event_category: "experiment",
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    } else {
      isProduction && console.error("no gtag");
    }
  },
});
gb.init({
  streaming: true,
});
