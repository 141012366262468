import { graphql } from "../../graphql";

export const UpdateArticleMutation = graphql(`
  mutation UpdateArticleMutation($overrides: FeedArticleOverrideInputType!, $feedId: Int!, $feedArticleId: Int!) {
  overrideFeedArticle(input: {feedId: $feedId, overrides: $overrides, feedArticleId: $feedArticleId}) {
    ... on FeedArticleType {
      id
    }
    ... on OperationInfo {
        __typename
        messages {
          message
        }
      }
  }
}
`);

export const deleteFeedArticleMutation = graphql(`
  mutation deleteFeedArticle($input: DeleteFeedArticleInput!) {
    deleteFeedArticle(input: $input) {
      ... on SuccessResponse {
        __typename
        message
      }
      ... on OperationInfo {
        __typename
        messages {
          message
        }
      }
    }
  }
`);

export const FEED_MUTATION = graphql(`
  mutation FeedMutation($data: FeedUpdateInput!, $feedId: Int!) {
    updateFeed(data: $data, id: $feedId) {
      ... on FeedType {
        name
        terms {
          id
          term
          description
        }
        knownTags {
          id
          tag
        }
      }
    }
  }
`);

export const CREATE_FEED_MUTATION = graphql(`
  mutation CreateFeedMutation($data: FeedCreateInput!) {
    createFeed(data: $data) {
      ... on FeedType {
        id
        name
        terms {
          term
          description
          id
        }
        knownTags {
          id
          tag
        }
      }
    }
  }
`);

export const UPLOAD_CSV_FILE = graphql(`
  mutation UploadCSVFile($feedId: ID!, $file: Upload!) {
    uploadCsvFile(input: { feedId: $feedId, file: $file }) {
      ... on SuccessResponse {
        message
      }
      ... on OperationInfo {
        messages {
          kind
          message
        }
      }
    }
  }
`);
