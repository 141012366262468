import toast from "@/components/ui/toast";
import {
  type ArticleCreationDataType,
  type ArticleCreationStatusType,
  type ArticleNewsCategoryType,
  type ArticlePublisherType,
  type FeedWithAugTypes,
  UpdateArticleMutation,
  createArticleSubscription,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { toSnakeCase } from "@/lib/utils/prettyName";
import type { ArticleEditFn } from "@/types/article";
import type { SortOptions } from "@/types/shared";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import { updatePagedFeed } from "./feed.actions";

export const changeSort = (sortOrder: SortOptions) => {
  useFeedParamsStore.setState(
    { sortOrder },
    false,
    "[User] Sort: Updated order",
  );
  updatePagedFeed();
};

export const addArticle = async (articleUrl: string) => {
  const feedId = useFeedStore.getState().feedId;

  if (!feedId) {
    console.error("Could not find the feed ID in the store");
    return Promise.reject(new Error("Feed ID is missing"));
  }

  return new Promise<ArticleCreationDataType>((resolve, reject) => {
    client
      .subscription(createArticleSubscription, {
        articleUrl,
        feedId: `${feedId}`,
      })
      .subscribe(({ data, error }) => {
        if (error) {
          console.error(error);
          reject(error);
          return;
        }

        const validStatuses: ArticleCreationStatusType[] = [
          "COMPLETE",
          "PARTIAL_COMPLETE",
          "ERROR",
        ];

        if (
          data?.articleCreation &&
          validStatuses.includes(data.articleCreation.status)
        ) {
          resolve(data.articleCreation);
          return;
        }
      });
  });
};

export const updateArticle: ArticleEditFn = async (changes) => {
  const { articleId, ...overrides } = changes;
  const {
    headline,
    summary,
    author,
    publisher,
    sentimentPolarity,
    sentimentRationale,
    category,
  } = overrides;

  const { feedItems, feedId } = useFeedStore.getState();

  const updateItemFn = <T extends Partial<FeedWithAugTypes>>(item: T): T => {
    if (item?.articleId !== +articleId) return item;
    if (headline) item.headline = headline;
    if (summary) item.summary = summary;
    if (author && item.articleAuthors?.[0]) {
      const [mainAuthor, ...rest] = item.articleAuthors;
      item.articleAuthors = [{ ...mainAuthor, name: author }, ...rest];
    }
    if (sentimentPolarity && sentimentRationale) {
      item.articleSentiment = {
        polarity: sentimentPolarity,
        rationale: sentimentRationale,
      };
    }
    if (category)
      item.articleNewsCategory = toSnakeCase(
        category,
      ) as ArticleNewsCategoryType;
    if (publisher) {
      item.articlePublisher = {
        ...item.articlePublisher,
        name: publisher.name,
        url: publisher.url,
        id: +publisher.id,
        logoUrl: publisher.logoUrl,
      } as ArticlePublisherType;
    }
    return item;
  };

  if (!feedId) return false;

  const result = client
    .mutation(UpdateArticleMutation, {
      feedId,
      feedArticleId: +articleId,
      overrides,
    })
    .toPromise();

  const { data } = await result;

  const overrideFeedData = data?.overrideFeedArticle;

  if (!overrideFeedData) return false;

  if (overrideFeedData.__typename === "OperationInfo") {
    for (const message of overrideFeedData.messages) {
      toast.error(message.message);
    }

    return false;
  }

  if (
    overrideFeedData.__typename === "FeedArticleType" &&
    overrideFeedData.id
  ) {
    const updatedFeedItems = feedItems.map(updateItemFn);

    useFeedStore.setState(
      {
        feedItems: updatedFeedItems,
      },
      false,
      "[User] Article: Updated details",
    );

    return true;
  }
  return false;
};

export const removeArticles = (ids: number[]) =>
  useFeedStore.setState(
    {
      removalSet: useFeedStore
        .getState()
        .removalSet.union(new Set<number>(ids)),
    },
    false,
    "[User] Articles: Removed from feed",
  );
