import { DropdownButton } from "@/components/ui/button";
import { Menu, MenuItem, MenuPopover, MenuTrigger } from "@/components/ui/menu";
import { changeSort } from "@/store/news/articles.actions";
import { useFeedParamsStore } from "@/store/useFeedStore";
import type { OptionElementType, SortOptions } from "@/types/shared";
import { SortAscending, SortDescending } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

const sortOptions: OptionElementType<SortOptions>[] = [
  {
    shortLabel: "Score",
    label: "Score: Highest to Lowest",
    icon: <SortAscending className="w-5 h-5" />,
    value: "SCORE_DESC",
  },
  {
    shortLabel: "Score",
    label: "Score: Lowest to Highest",
    icon: <SortDescending className="w-5 h-5" />,
    value: "SCORE_ASC",
  },
  {
    shortLabel: "Pub Date",
    label: "Publish Date: Newest to Oldest",
    icon: <SortAscending className="w-5 h-5" />,
    value: "DATE_DESC",
  },
  {
    shortLabel: "Pub Date",
    label: "Publish Date: Oldest to Newest",
    icon: <SortDescending className="w-5 h-5" />,
    value: "DATE_ASC",
  },
];

export const FeedSort = () => {
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    const sortBy = useFeedParamsStore.getState().sortOrder;
    setSelectedSortOption(
      sortOptions.find((option) => option.value === sortBy),
    );
  }, []);

  return (
    // <div className="flex justify-end">
    <MenuTrigger>
      <DropdownButton
        ariaLabel="Sort By Options"
        icon={selectedSortOption?.icon}
        label={selectedSortOption?.shortLabel || "Landing Page"}
      />
      <MenuPopover>
        <Menu className="bg-white">
          {sortOptions.map((option) => (
            <MenuItem
              key={option.value}
              onAction={() => {
                changeSort(option.value);
                setSelectedSortOption(option);
              }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </MenuPopover>
    </MenuTrigger>
    // </div>
  );
};
