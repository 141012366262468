import { Button } from "@/components/ui/button";
import { useAppNavigation } from "@/lib/navigation";

type feedItemItemProps = {
  feedId: string;
  feedName: string;
};

const feedItemItem = ({ feedId, feedName }: feedItemItemProps) => {
  const { navigateToFeed } = useAppNavigation();

  const handleFeedSelectionAndNavigation = (feedId: number) => {
    navigateToFeed(feedId);
  };

  return (
    <div
      // biome-ignore lint/a11y/useSemanticElements: <to avoid double button issue>
      role="button"
      tabIndex={0}
      className="relative focus-visible:outline-none w-[900px] h-auto mt-0 mx-auto mb-5 focus-visible:ring-2 focus-visible:ring-blue-750 bg-white shadow border rounded-md border-slate-100 flex items-center flex-col cursor-pointer transition-colors"
      onClick={() => handleFeedSelectionAndNavigation(+feedId)}
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleFeedSelectionAndNavigation(+feedId);
        }
      }}
    >
      <div className="bottom-0 flex w-full my-4 px-8 justify-between items-center">
        <h1 className="text-3xl text-slate-800 font-serif">{feedName}</h1>
        <div className="flex items-center gap-2">
          <Button
            variant="secondary"
            className="bg-slate-100 text-black hover:bg-slate-200 hover:text-black"
            onPress={() => handleFeedSelectionAndNavigation(+feedId)}
          >
            View Newsfeed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default feedItemItem;
