import { graphql } from "../../graphql";

export const createArticleSubscription = graphql(`
  subscription CreateArticleSubscription($articleUrl: String!, $feedId: ID!) {
    articleCreation(articleUrl: $articleUrl, feedId: $feedId) {
      status
      feedArticle {
        id
      }
      uiMessage {
        type
        message
      }
    }
  }
`);

// Subscription Types
export type ArticleCreationDataType = ReturnType<
  Exclude<typeof createArticleSubscription.__apiType, undefined>
>["articleCreation"];

export type ArticleCreationStatusType = ReturnType<
  Exclude<typeof createArticleSubscription.__apiType, undefined>
>["articleCreation"]["status"];
