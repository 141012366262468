import { toast } from "@/components/ui/toast";

const STORAGE_KEYS = [
  "access_token",
  "refresh_token",
  "recent-searches",
  "newsamp_user",
  "persist:root",
];

export const clearLocalStorage = () => {
  for (const key of STORAGE_KEYS) {
    window.localStorage.removeItem(key);
  }
};

export const handleSessionExpired = () => {
  clearLocalStorage();
  toast.error("Your session has expired. Please log in again.");
  // Let the component handle store updates
  window.location.href = "/login"; // Redirect to login page
};
