import { Button } from "@/components/ui/button";
import { CaretDown } from "@phosphor-icons/react";
import type React from "react";

interface ShowMoreButtonProps {
  showMore: boolean;
  onToggle: () => void;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  showMore,
  onToggle,
}) => {
  const getButtonContent = () => (
    <>
      <CaretDown
        size={16} // Increased size for better visibility
        className={`transition-transform mr-2 text-slate-500/50 ${
          showMore ? "rotate-180" : ""
        }`}
      />
      <span className="text-xs font-medium text-slate-700">
        Show{showMore ? " less" : " more"}
      </span>
    </>
  );

  return (
    // Using Button component as a wrapper with padding
    <Button
      variant="link"
      className="px-2 py-0 h-7 text-slate-800"
      onPress={onToggle}
    >
      {getButtonContent()}
    </Button>
  );
};

export default ShowMoreButton;
