import { Button, type ButtonProps } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { PencilSimple } from "@phosphor-icons/react";
import { forwardRef } from "react";

interface EditButtonProps extends ButtonProps {
  onEditClick?: () => void;
  onHoverChange?: (isHovering: boolean) => void;
  className?: string;
}

export const EditButton = forwardRef<HTMLButtonElement, EditButtonProps>(
  ({ onEditClick, onHoverChange, className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="outline"
        className={cn(
          "absolute right-0 transition group-hover:opacity-100 rounded-sm shadow-md bg-white hover:bg-white opacity-0",
          className,
        )}
        size="xsIcon"
        onPress={onEditClick}
        onHoverStart={() => onHoverChange?.(true)}
        onHoverEnd={() => onHoverChange?.(false)}
        {...props}
      >
        <PencilSimple className="fill-black" size={10} />
      </Button>
    );
  },
);
