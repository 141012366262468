import { Button } from "@/components/ui/button";
import DateRangePicker, {
  type onApplyFn,
} from "@/components/ui/date-range-picker";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import Checkbox from "@/components/ui/forms/checkbox";
import { Input, JollyTextField } from "@/components/ui/textfield";
import { useFeature } from "@/lib/feature-management";
import { cn } from "@/lib/utils";
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import { updateDateRange } from "@/store/news/feed.actions";
import {
  filterBySearchTerms,
  isFeedFiltered,
} from "@/store/news/filterSearch.actions";
import {
  useFeedPercentage,
  useFilteredFeedItems,
  useFilteredItemsTotal,
} from "@/store/news/news.selectors";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { FeedExport } from "./FeedExport";
import { FeedSort } from "./FeedSort";

interface FeedSearchProps {
  allArticleIds: number[];
  articleIdsToDelete: number[];
  deleteAllArticles: boolean;
  toggleDeleteAllArticles: (ids: number[]) => void;
  style?: React.CSSProperties;
}

export const FeedSearch: React.FC<FeedSearchProps> = ({
  allArticleIds,
  articleIdsToDelete,
  deleteAllArticles,
  toggleDeleteAllArticles,
  style,
}) => {
  const [fetchingFeed, fetchingSearch] = useFeedStore((state) => [
    state.fetchingFeed,
    state.fetchingSearch,
  ]);
  const [filterDays, isCustomDaysRange, startDate, endDate, searchTerms] =
    useFeedParamsStore((state) => [
      state.filterDays,
      state.isCustomDaysRange,
      state.startDate,
      state.endDate,
      state.searchTerms,
    ]);

  const feedPercentage = useFeedPercentage();
  const filteredFeedItems = useFilteredFeedItems();
  const filteredFeedItemsCount = useFilteredItemsTotal();

  const [isFilteredUI, setIsFilteredUI] = useState<boolean>(isFeedFiltered());
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  const hasChartAccess = useFeature("enable-charts", false);

  useEffect(() => {
    setIsFilteredUI(isFeedFiltered());
  }, [filteredFeedItems]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Only to restore the existing search terms
    setSearchTerm(searchTerms);
  }, [searchTerms]);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    if (isAdvancedSearchOpen) return;

    debounceTimeout.current = setTimeout(() => {
      filterBySearchTerms(searchTerm);
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  const handleSubmitAdvancedSearch = () => {
    filterBySearchTerms(searchTerm);
  };

  const onApply: onApplyFn = (range) => {
    updateDateRange(range.days, range.from, range.to, range.isCustom);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterBySearchTerms(searchTerm);
    }
  };

  const renderNumberOfArticles = () => {
    return (
      <>
        <span className="font-semibold">
          {addCommasToNumbers(filteredFeedItemsCount)}{" "}
        </span>
        <span>{pluralize(filteredFeedItemsCount, "Article")} </span>
        {isFilteredUI &&
          feedPercentage !== 0 &&
          feedPercentage &&
          !Number.isNaN(feedPercentage) && <span>({feedPercentage}%)</span>}
        {isFilteredUI && feedPercentage === 0 && (
          <span className={"text-xs text-gray-600"}>
            (Adjust filters to see more)
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={cn(
          "max-w-[956px] lg:mx-auto  z-30 sticky",
          hasChartAccess ? "top-[88px]" : "top-0",
        )}
        style={style}
      >
        {isAdvancedSearchOpen && (
          <div className="px-4 py-4 bg-white">
            <JollyTextField
              textArea
              label="Advanced Search"
              value={searchTerm}
              onChange={(searchTerm) => setSearchTerm(searchTerm)}
              onKeyDown={handleKeyPress}
            />
            <div className="flex justify-end gap-2 mt-2">
              <Button
                type="button"
                variant="ghost"
                onPress={() => setIsAdvancedSearchOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="outline" onPress={handleSubmitAdvancedSearch}>
                Submit
              </Button>
            </div>
          </div>
        )}
        <div className="flex px-3 pt-2 items-baseline justify-between bg-white">
          <div className="text-sm flex-grow text-slate-900/95 max-w-64 flex-1 leading-tight tracking-[0.14px]">
            {fetchingFeed || fetchingSearch ? (
              <Skeleton className="w-32 h-5 bg-slate-300/50" />
            ) : (
              <>
                <Checkbox
                  checked={deleteAllArticles}
                  className={"mr-4"}
                  isSelectAll={true}
                  isPartialSelect={
                    !!articleIdsToDelete.length &&
                    articleIdsToDelete.length !== allArticleIds.length
                  }
                  onCheckedChange={() => toggleDeleteAllArticles(allArticleIds)}
                />
                {renderNumberOfArticles()}
              </>
            )}
          </div>

          <div className="flex gap-1 items-end pb-1.5">
            {!isAdvancedSearchOpen && (
              <div className="relative flex items-center w-auto">
                <MagnifyingGlass
                  size={16}
                  className="text-gray-600 absolute left-2.5"
                />
                <Input
                  placeholder="Search for article"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  className={cn(
                    "w-80 mr-1 h-9 py-2 pl-[2rem] pr-[4.5rem] items-end transition-width duration-200 ease-in-out",
                  )}
                />
                <Button
                  type="button"
                  variant="input"
                  size="input"
                  onPress={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
                >
                  Expand
                </Button>
              </div>
            )}
            {!hasChartAccess && (
              <DateRangePicker
                filterDays={filterDays}
                isCustomDaysRange={isCustomDaysRange}
                onApply={onApply}
                startDate={startDate}
                endDate={endDate}
              />
            )}
            <FeedSort />
            {!hasChartAccess && <FeedExport />}
          </div>
        </div>
        <div className="z-10 bg-gradient-to-b from-white via-white/50 via-70% h-4 -mx-1 to-transparent" />
      </div>
    </>
  );
};
