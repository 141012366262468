import NewsFeedPublisherPanel from "@/components/news/feed/panels/NewsFeedPublisherPanel";
import {
  AddNewsFeedPanel,
  EditNewsFeedPanel,
} from "@/components/news/feed/panels/NewsFeedSettingPanel";
import { Sheet } from "@/components/ui/controls/sheet";
import { useAppNavigation } from "@/lib/navigation";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import {
  useFeedName,
  useFeedTerms,
  useKnownTags,
} from "@/store/news/news.selectors";
import useFeedStore from "@/store/useFeedStore";
import usePublishersStore from "@/store/usePublishersStore";
import { useEffect, useRef } from "react";

const FeedSheets: React.FC = () => {
  const [feedId, creatingOrEditingFeed, createOrEditFeedState] = useFeedStore(
    (state) => [
      state.feedId,
      state.creatingOrEditingFeed,
      state.createOrEditFeedState,
    ],
  );
  const [isOpen, mode, closePanel] = useFeedPanelStore((state) => [
    state.isOpen,
    state.mode,
    state.closePanel,
  ]);
  const displayPanel = usePublishersStore((state) => state.displayPanel);
  const feedName = useFeedName();
  const feedTerms = useFeedTerms();
  const knownTags = useKnownTags();

  const feedIdRef = useRef(feedId);
  const { navigateToFeed } = useAppNavigation();

  useEffect(() => {
    if (!creatingOrEditingFeed && createOrEditFeedState === "complete") {
      closePanel();
      if (feedId !== feedIdRef.current && feedId) {
        navigateToFeed(feedId);
      }
    }
  }, [creatingOrEditingFeed, createOrEditFeedState, feedId]);

  return (
    <>
      <Sheet
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) closePanel();
        }}
      >
        {mode === "edit" ? (
          <EditNewsFeedPanel
            name={feedName}
            feedTerms={feedTerms}
            feedTags={knownTags}
          />
        ) : (
          <AddNewsFeedPanel />
        )}
      </Sheet>
      <Sheet open={displayPanel}>
        {displayPanel && <NewsFeedPublisherPanel />}
      </Sheet>
    </>
  );
};

export default FeedSheets;
