import { graphql } from "../../graphql";

export const NewsFeedsSearchQuery = graphql(`
  query NewsFeedSearchQuery(
    $feedId: Int!
    $searchTerm: String!
    $ids: [Int!]
  ) {
    feedSearch(
      input: {
        search: {
          feedId: $feedId
          searchTerm: $searchTerm
          ids: $ids
          advancedSearch: true
        }
      }
    ) {
      term
      items {
        id
        field {
          name
          content
        }
      }
    }
  }
`);

export const DiscoveredTagQuery = graphql(
  `
    query DiscoveredTagQuery(
      $feedId: Int!
      $startDate: DateTime
      $endDate: DateTime
    ) {
      feedArticleTagsByFeed(
        feedId: $feedId
        startDate: $startDate
        endDate: $endDate
        tagKinds: ["events", "topics", "initiatives", "people", "themes"]
      ) {
        tagKind
        tag
        feedArticleIds
      }
    }
  `,
);

export const NewsFeedPublishers = graphql(`
  query NewsFeedPublishers($pubFilter: String = "", $pubLimit: Int = 50) {
    publishers(pagination: { limit: $pubLimit }, filter: $pubFilter) {
      id
      name
      url
      logos {
        image {
          url
        }
      }
    }
  }
`);
