import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { useMemo, useRef } from "react";

export function useFilterSorting(
  filters: DerivedFilterItem[],
  searchTerm: string,
  sorted: boolean,
  title: string,
) {
  const sortedFilterIdsRef = useRef<string[]>([]);
  const sortedFilterMapRef = useRef<Map<string, number>>(new Map());

  return useMemo(() => {
    let result = filters;
    const anyItemSelected = filters.some((filter) => filter.selected);
    const hasPreviousSort = sortedFilterIdsRef.current.length > 0;

    // Apply search filter if needed
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      const searchCache = new Map<string, boolean>();

      result = result.filter((filter) => {
        // Check cache first
        const cacheKey = `${filter.value}_${searchLower}`;
        if (searchCache.has(cacheKey)) {
          return searchCache.get(cacheKey);
        }

        const matches = filter.value.toLowerCase().includes(searchLower);
        searchCache.set(cacheKey, matches);
        return matches;
      });
    }

    // Only sort if needed and update sort order map
    if (sorted && (!anyItemSelected || !hasPreviousSort)) {
      result = result.slice().sort((a, b) => b.count - a.count);

      // Store the sorted order and create a map for O(1) lookups
      sortedFilterIdsRef.current = result.map((f) => f.id);
      sortedFilterMapRef.current = new Map(
        sortedFilterIdsRef.current.map((id, index) => [id, index]),
      );
    }

    // If we have a previous sort order, maintain it using the map for O(1) lookups
    if (hasPreviousSort) {
      result = result.slice().sort((a, b) => {
        const indexA = sortedFilterMapRef.current.get(a.id) ?? 0;
        const indexB = sortedFilterMapRef.current.get(b.id) ?? 0;
        return indexA - indexB;
      });
    }

    return result;
  }, [filters, searchTerm, sorted, title]);
}
