import type { FeedWithAugTypes } from "@/data-access/news/feed.types";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
export const TIER_2_MAX = 80;
export const TIER_3_MAX = 60;
export const TIER_1_COLOR = "#FFD700";
export const TIER_2_COLOR = "#C0C0C0";
export const TIER_3_COLOR = "#CD7F32";

export const formatLastUpdateDate = (lastUpdateDate: string) => {
  const currentDate = new Date();
  const date = new Date(lastUpdateDate);

  const diffInMilliseconds = Math.abs(+currentDate - +date);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

  const formattedDate = `${getDayOfWeek(date)} at ${formatTime(date)}`;

  if (diffInMinutes < 1440) {
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    const hoursText = hours > 0 ? `${hours} hours` : "";
    return `updated last ${hoursText} ${minutes} minutes ago`;
  }

  return `updated last ${formattedDate}`;
};

export const getDayOfWeek = (date: Date) => {
  const options = { weekday: "long" as const };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const hasSecondSection = (article: Partial<FeedWithAugTypes>) => {
  return !!(
    article.summary ||
    Boolean(article.firstArticleMention) ||
    article.searchHit
  );
};

export const transformSummary = (summary: string) => {
  const lines = summary.split("\n");
  const paragraphs = [];

  const checkSplittableCharacter = (line: string) => {
    return (
      line.includes(".* ") ||
      line.includes(".* ") ||
      line.includes("?* ") ||
      line.includes("?• ")
    );
  };

  const unwantedPhrasesRegex =
    /\b(not (explicitly )?mentioned?|no (explicit mentions?|mention of|further information about|details provided on|additional points mentioned|specific mentions?|detailed information|specific information provided)|does not (explicitly )?(mention|provide further details|specify|any information)|(?:no|not) .* (specific (role|responsibilities?|details?|information)|detailed information)|(no|not enough|insufficient) .* (information|details?)|uncertain details|details are unclear|is not (mentioned|provided)|no mention)\b/i;

  for (const line of lines) {
    const trimmedLine = line.trim();

    if (trimmedLine !== "" && !unwantedPhrasesRegex.test(trimmedLine)) {
      // If the line starts with "• ", preserve the bullet point
      if (trimmedLine.startsWith("• ")) {
        paragraphs.push(trimmedLine);
      } else {
        const containsSplittableCharacter =
          checkSplittableCharacter(trimmedLine);
        if (
          containsSplittableCharacter &&
          !trimmedLine.endsWith(".*") &&
          !trimmedLine.endsWith("•")
        ) {
          const presentCharacter = trimmedLine.includes("* ") ? "* " : "• ";
          const sentences = trimmedLine.split(presentCharacter);
          if (sentences.length > 1) {
            const formattedSentences = sentences.map((sentence, index) =>
              index === 0 ? `${sentence}` : `${presentCharacter} ${sentence}`,
            );
            paragraphs.push(...formattedSentences);
          } else {
            paragraphs.push(trimmedLine);
          }
        } else {
          paragraphs.push(trimmedLine);
        }
      }
    }
  }

  return paragraphs;
};

export const formatTime = (date: Date) => {
  const options = {
    hour: "numeric" as const,
    minute: "2-digit" as const,
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export type FilterMap = {
  [key: string]: string[];
};

export const formatNumber = (num: number): string => {
  if (num === 0) {
    return "0";
  }
  const units = ["", "K", "M", "B", "T"];
  const k = 1000.0;
  const magnitude = Math.floor(Math.log(num) / Math.log(k));
  let formattedNumber = (num / k ** magnitude).toFixed(1);

  // Convert to a number and back to string to remove unnecessary trailing zeros
  formattedNumber = Number.parseFloat(formattedNumber).toString();

  return `${formattedNumber}${units[magnitude]}`;
};

export const formattedDateToUTC = (date?: string | Date | null): string => {
  let parsedDate: Date;

  if (date === undefined || date === null) {
    parsedDate = new Date();
  } else if (typeof date === "string") {
    parsedDate = parseISO(date);
    if (Number.isNaN(parsedDate.getTime())) {
      throw new RangeError("Invalid date string");
    }
  } else if (date instanceof Date) {
    if (Number.isNaN(date.getTime())) {
      throw new RangeError("Invalid date object");
    }
    parsedDate = date;
  } else {
    throw new TypeError("Invalid input type");
  }

  const utcDate = toZonedTime(parsedDate, "UTC");

  return format(utcDate, "MMM dd, yyyy", {
    timeZone: "UTC",
  });
};
