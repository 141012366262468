import { formattedDateToUTC } from "@/components/news/shared/utils";
import { Separator } from "@/components/ui/controls/separator";
import { DateField, DateInput } from "@/components/ui/datefield";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import { toast } from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import {
  type CalendarDate,
  type CalendarDateTime,
  type ZonedDateTime,
  getLocalTimeZone,
  parseDate,
} from "@internationalized/date";
import { Calendar as CalendarIcon, X } from "@phosphor-icons/react";
import { format } from "date-fns-tz";
import { useEffect, useReducer, useRef, useState } from "react";
import { Calendar } from "../../ui/data-display/calendar";

interface EditableDateProps {
  initialDate?: string | null;
  onSave: (date: Date) => void;
  styles?: string;
  nonHoverClick?: () => void;
}

type DateFieldValue = CalendarDate | CalendarDateTime | ZonedDateTime | null;

interface DateState {
  inputDate: DateFieldValue;
  selectedDate: Date | null;
  monthDate: Date;
}

type DateAction =
  | { type: "SET_DATE"; payload: { date: Date | null } }
  | { type: "SET_INPUT"; payload: DateFieldValue }
  | { type: "CLEAR" }
  | { type: "SET_MONTH"; payload: Date };

const EditableDate: React.FC<EditableDateProps> = ({
  initialDate,
  onSave,
  styles,
  nonHoverClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const dateFieldRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [state, dispatch] = useReducer(
    (state: DateState, action: DateAction) => {
      switch (action.type) {
        case "SET_DATE": {
          const { date } = action.payload;
          if (!date) {
            return {
              ...state,
              inputDate: null,
              selectedDate: null,
            };
          }
          try {
            const formattedDate = format(date, "yyyy-MM-dd");
            return {
              ...state,
              inputDate: parseDate(formattedDate) as CalendarDate,
              selectedDate: date,
              monthDate: date,
            };
          } catch (error) {
            return state;
          }
        }
        case "SET_INPUT": {
          return {
            ...state,
            inputDate: action.payload,
          };
        }
        case "CLEAR": {
          return {
            ...state,
            inputDate: null,
            selectedDate: null,
            monthDate: new Date(),
          };
        }
        case "SET_MONTH": {
          return {
            ...state,
            monthDate: action.payload,
          };
        }
      }
    },
    {
      inputDate: null,
      selectedDate: null,
      monthDate: new Date(),
    },
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setIsHovered(false);
  };

  const handleDateChange = (date: Date | undefined) => {
    dispatch({ type: "SET_DATE", payload: { date: date ?? null } });
  };

  const handleDateFieldChange = (value: DateFieldValue) => {
    dispatch({ type: "SET_INPUT", payload: value });
    if (value && isEditing) {
      try {
        const date = value.toDate(getLocalTimeZone());
        if (!Number.isNaN(date.getTime())) {
          dispatch({ type: "SET_DATE", payload: { date } });
        }
      } catch (error) {
        // Invalid date format, ignore
      }
    }
  };

  const handleDateSave = () => {
    if (!state.selectedDate) {
      toast.error("Please select a date.");
      return;
    }
    setIsEditing(false);
    onSave(state.selectedDate);
  };

  const handleDateCancel = () => {
    setIsEditing(false);
    if (initialDate) {
      const date = new Date(initialDate);
      if (!Number.isNaN(date.getTime())) {
        dispatch({ type: "SET_DATE", payload: { date } });
      }
    }
  };

  const handleDateClear = () => {
    dispatch({ type: "CLEAR" });
  };

  // Initialize dates when initialDate changes
  useEffect(() => {
    if (!isEditing && initialDate) {
      const date = new Date(initialDate);
      if (!Number.isNaN(date.getTime())) {
        dispatch({ type: "SET_DATE", payload: { date } });
      }
    }
  }, [initialDate, isEditing]);

  // Handle click outside
  useEffect(() => {
    if (!isEditing) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event?.target as Node) &&
        !popoverRef.current?.contains(event?.target as Node)
      ) {
        handleDateCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isEditing, handleDateCancel]);

  const focusDateInput = () => {
    if (dateFieldRef.current) {
      dispatch({ type: "SET_INPUT", payload: null });

      const inputElement = dateFieldRef.current.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleDateSave();
    } else if (e.key === "Escape") {
      handleDateCancel();
    }
  };

  return (
    <div
      className="inline-flex items-start justify-start text-xs text-slate-500"
      ref={containerRef}
    >
      {isEditing ? (
        <div className="w-full relative">
          <Popover open={true}>
            <PopoverTrigger asChild>
              <div
                className="relative py-1 pl-1.5 -ml-1.5 pr-2 rounded-sm text-xs text-left overflow-hidden leading-tight text-slate-900 line-clamp-3 inline-flex items-center border border-blue-600"
                onDoubleClick={focusDateInput}
              >
                <CalendarIcon
                  className="fill-slate-600 cursor-pointer"
                  size={16}
                  onClick={handleDateCancel}
                />
                <DateField
                  value={state.inputDate}
                  onChange={handleDateFieldChange}
                  className="pl-1 pr-4 w-[150px]"
                  ref={dateFieldRef}
                  onKeyDown={handleKeyDown}
                >
                  <DateInput variant="ghost" />
                </DateField>
                {state.inputDate && (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div
                    className="rounded-full w-[13px] h-[13px] p-0.5 bg-gray-400 cursor-pointer"
                    onClick={handleDateClear}
                  >
                    <X className="text-white" size={9} />
                  </div>
                )}
              </div>
            </PopoverTrigger>
            <PopoverContent
              className="w-auto p-0"
              align="start"
              ref={popoverRef}
              onKeyDown={handleKeyDown} // Add keydown handler here
            >
              <Calendar
                initialFocus
                mode="single"
                month={state.monthDate}
                selected={state.selectedDate ?? undefined}
                onSelect={handleDateChange}
                onMonthChange={(date) =>
                  dispatch({ type: "SET_MONTH", payload: date })
                }
                numberOfMonths={1}
              />
              <Separator />
              <div className="p-2.5">
                <ButtonGroup
                  primaryLabel="Save"
                  primaryAction={handleDateSave}
                  secondaryLabel="Cancel"
                  secondaryAction={handleDateCancel}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      ) : (
        <div
          className={cn(
            "relative py-[7px] text-xs text-left overflow-hidden leading-snug pl-1.5 pr-8 text-slate-900 line-clamp-3 group -ml-1.5 hover:bg-slate-200/20 rounded",
            styles,
          )}
          role={nonHoverClick && "button"}
          onClick={() => {
            if (!isHovered) {
              nonHoverClick?.();
            }
            handleEditClick();
          }}
          onKeyDown={(e) => {
            if (!isHovered && (e.key === "Enter" || e.key === " ")) {
              nonHoverClick?.();
              handleEditClick();
            }
          }}
        >
          <p className="min-w-24 text-xs text-gray-800">
            {state.selectedDate
              ? formattedDateToUTC(state.selectedDate)
              : "No date selected"}
          </p>
          <EditButton
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-1.5 right-2"
          />
        </div>
      )}
    </div>
  );
};

export default EditableDate;
