"use client";

import { cn } from "@/lib/utils";
import {
  AvailableChartColors,
  type AvailableChartColorsKeys,
  type AvailableChartGradientsKeys,
  chartGradientColors,
  constructCategoryColors,
  deepEqual,
  getActiveClass,
  getGradientId,
  getInactiveClass,
  getYAxisDomain,
} from "@/lib/utils/chartUtils.ts";
import {
  type MouseEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Bar,
  type BarProps,
  CartesianGrid,
  Label,
  BarChart as RechartsBarChart,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  type XAxisProps,
  YAxis,
} from "recharts";
import type { AxisDomain } from "recharts/types/util/types";

import type {
  BarChartProps,
  BarShapeProps,
  PayloadItem,
  ShapeProps,
  TooltipProps,
} from "./BarChart.types";
import { ChartLegend } from "./ChartLegend";
import ChartTooltip from "./ChartTooltip";

//#region Shape

const BarShape = memo(function Shape<T extends PayloadItem>({
  props,
  activeBar,
  activeLegend,
  layout,
  mode,
  colorKey,
  borderRadius = 0,
}: BarShapeProps<T>): JSX.Element {
  const { name, payload, value, x = 0, y = 0, width = 0, height = 0 } = props;

  let correctedX = Number.parseInt(x.toString(), 10);
  let correctedY = Number.parseInt(y.toString(), 10);
  let correctedWidth = width;
  let correctedHeight = height;

  if (layout === "horizontal" && height < 0) {
    correctedY += height;
    correctedHeight = Math.abs(height);
  } else if (layout === "vertical" && width < 0) {
    correctedX += width;
    correctedWidth = Math.abs(width);
  }

  const fill =
    mode === "gradient" ? `url(#${getGradientId(colorKey)})` : undefined;

  const isInactive = useMemo(
    () =>
      activeBar || (activeLegend && name && activeLegend !== name)
        ? !deepEqual(activeBar, { ...payload, value })
        : false,
    [activeBar, activeLegend, name, payload, value],
  );

  return (
    <rect
      x={correctedX}
      y={correctedY}
      width={correctedWidth}
      height={correctedHeight}
      fill={fill}
      className={cn({
        [getActiveClass(colorKey)]: !isInactive && mode === "solid",
        [getInactiveClass(colorKey)]: isInactive,
      })}
      rx={borderRadius}
      ry={borderRadius}
      stroke={value === 0 ? "none" : "white"}
      strokeWidth={value === 0 ? 0 : 1}
    />
  );
});

BarShape.displayName = "BarShape";

// #region RotatedXAxisTick
const RotatedXAxisTick = memo(
  ({
    x,
    y,
    payload,
    formatXAxisTick,
    index,
  }: {
    x: number;
    y: number;
    payload: any;
    formatXAxisTick?: XAxisProps["tickFormatter"];
    index: number;
  }) => (
    <text
      x={x}
      y={y}
      dy={10}
      textAnchor="end"
      transform={`rotate(-45, ${x}, ${y})`}
      className="fill-gray-500 text-[9px] dark:fill-gray-300"
    >
      {formatXAxisTick ? formatXAxisTick(payload.value, index) : payload.value}
    </text>
  ),
);

RotatedXAxisTick.displayName = "RotatedXAxisTick";

//#region BarChart
const BarChart = memo(
  forwardRef<HTMLDivElement, BarChartProps<PayloadItem>>(
    (props, forwardedRef) => {
      const {
        data = [],
        categories = [],
        index,
        colors = AvailableChartColors,
        valueFormatter = (value: number) => value.toString(),
        startEndOnly = false,
        showXAxis = true,
        showYAxis = true,
        showGridLines = true,
        yAxisWidth = 56,
        intervalType = "equidistantPreserveStart",
        showTooltip = true,
        showLegend = true,
        autoMinValue = true,
        minValue,
        maxValue,
        allowDecimals = true,
        className,
        onValueChange,
        enableLegendSlider = false,
        barCategoryGap,
        tickGap = 5,
        xAxisLabel,
        yAxisLabel,
        layout = "horizontal",
        type = "default",
        legendPosition = "right",
        tooltipCallback,
        customTooltip,
        borderRadius,
        formatXAxisTick,
        rotateXAxisTick,
        mode = "solid",
        defaultActiveBar,
        ...other
      } = props;

      const [legendHeight, setLegendHeight] = useState(60);
      const [activeLegend, setActiveLegend] = useState<string | undefined>(
        undefined,
      );
      const [activeBar, setActiveBar] = useState<any | undefined>(
        defaultActiveBar,
      );
      const prevActiveRef = useRef<boolean | undefined>(undefined);
      const prevLabelRef = useRef<string | undefined>(undefined);

      const CustomTooltip = customTooltip;
      const paddingValue =
        (!showXAxis && !showYAxis) || (startEndOnly && !showYAxis) ? 0 : 20;
      const categoryColors = useMemo(
        () => constructCategoryColors(categories, colors),
        [categories, colors],
      );
      const yAxisDomain = useMemo(
        () => getYAxisDomain(autoMinValue, minValue, maxValue),
        [autoMinValue, minValue, maxValue],
      );
      const hasOnValueChange = !!onValueChange;
      const stacked = type === "stacked" || type === "percent";

      useEffect(() => {
        setActiveBar(defaultActiveBar);
      }, [defaultActiveBar]);

      const valueToPercent = useCallback((value: number) => {
        return `${(value * 100).toFixed(0)}%`;
      }, []);

      const onBarClick = useCallback(
        (data: any, _: any, event: MouseEvent) => {
          event.stopPropagation();
          if (!onValueChange) return;

          if (deepEqual(activeBar, { ...data.payload, value: data.value })) {
            setActiveLegend(undefined);
            setActiveBar(undefined);
            onValueChange?.(null);
          } else {
            setActiveLegend(data.tooltipPayload?.[0]?.dataKey);
            setActiveBar({
              ...data.payload,
              value: data.value,
            });
            onValueChange?.({
              eventType: "bar",
              categoryClicked: data.tooltipPayload?.[0]?.dataKey,
              value: data.value,
              ...data.payload,
            });
          }
        },
        [activeBar, onValueChange],
      );

      // const onCategoryClick = useCallback(
      //   (dataKey: string) => {
      //     if (!hasOnValueChange) return;
      //
      //     if (dataKey === activeLegend && !activeBar) {
      //       setActiveLegend(undefined);
      //       onValueChange?.(null);
      //     } else {
      //       setActiveLegend(dataKey);
      //       onValueChange?.({
      //         eventType: "category",
      //         categoryClicked: dataKey,
      //       });
      //     }
      //     setActiveBar(undefined);
      //   },
      //   [activeLegend, activeBar, hasOnValueChange, onValueChange],
      // );

      const handleChartClick = useCallback(() => {
        if (hasOnValueChange && (activeLegend || activeBar)) {
          setActiveBar(undefined);
          setActiveLegend(undefined);
          onValueChange?.(null);
        }
      }, [hasOnValueChange, activeLegend, activeBar, onValueChange]);

      return (
        <div
          ref={forwardedRef}
          className={cn("h-80 w-full", className)}
          tremor-id="tremor-raw"
          {...other}
        >
          <ResponsiveContainer>
            <RechartsBarChart
              data={data}
              onClick={
                hasOnValueChange && (activeLegend || activeBar)
                  ? handleChartClick
                  : undefined
              }
              margin={{
                bottom: xAxisLabel ? 30 : rotateXAxisTick ? 40 : undefined,
                left: yAxisLabel ? 20 : undefined,
                right: yAxisLabel ? 5 : undefined,
                top: 5,
              }}
              stackOffset={type === "percent" ? "expand" : undefined}
              layout={layout}
              barCategoryGap={barCategoryGap}
            >
              {mode === "gradient" && (
                <defs>
                  {categories.map((category) => (
                    <linearGradient
                      key={category}
                      id={getGradientId(
                        categoryColors.get(
                          category,
                        ) as AvailableChartColorsKeys,
                      )}
                      gradientTransform={"rotate(90)"}
                    >
                      {chartGradientColors[
                        categoryColors.get(
                          category,
                        ) as AvailableChartGradientsKeys
                      ]?.stops.map((stop, index) => (
                        <stop
                          // biome-ignore lint/suspicious/noArrayIndexKey: <bar stops no unique key>
                          key={index}
                          offset={stop.offset}
                          stopColor={stop.color}
                          stopOpacity="1"
                        />
                      ))}
                    </linearGradient>
                  ))}
                </defs>
              )}

              {showGridLines ? (
                <CartesianGrid
                  className={cn(
                    "stroke-gray-200 stroke-1 dark:stroke-gray-800",
                  )}
                  horizontal={layout !== "vertical"}
                  vertical={layout === "vertical"}
                />
              ) : null}
              <XAxis
                hide={!showXAxis}
                tick={
                  rotateXAxisTick
                    ? (props) => (
                        <RotatedXAxisTick
                          {...props}
                          formatXAxisTick={formatXAxisTick}
                        />
                      )
                    : {
                        transform:
                          layout !== "vertical" ? "translate(0, 6)" : undefined,
                      }
                }
                fill=""
                stroke=""
                className={cn(
                  "text-[9px]",
                  "fill-gray-500 dark:fill-gray-500",
                  { "mt-4": layout !== "vertical" },
                )}
                tickLine={false}
                axisLine={false}
                minTickGap={tickGap}
                tickFormatter={formatXAxisTick}
                {...(layout !== "vertical"
                  ? {
                      padding: {
                        left: paddingValue,
                        right: paddingValue,
                      },
                      dataKey: index,
                      interval: startEndOnly
                        ? "preserveStartEnd"
                        : intervalType,
                      ticks: startEndOnly
                        ? //@ts-ignore
                          [data[0][index], data[data.length - 1][index]]
                        : undefined,
                    }
                  : {
                      type: "number",
                      domain: yAxisDomain as AxisDomain,
                      tickFormatter:
                        type === "percent" ? valueToPercent : valueFormatter,
                      allowDecimals: allowDecimals,
                    })}
              >
                {xAxisLabel && (
                  <Label
                    position="insideBottom"
                    offset={-20}
                    className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                  >
                    {xAxisLabel}
                  </Label>
                )}
              </XAxis>
              <YAxis
                width={yAxisWidth}
                hide={!showYAxis}
                axisLine={false}
                tickLine={false}
                fill=""
                stroke=""
                className={cn("text-[9px]", "fill-gray-500 dark:fill-gray-500")}
                tick={{
                  transform:
                    layout !== "vertical"
                      ? "translate(-3, 0)"
                      : "translate(0, 0)",
                }}
                {...(layout !== "vertical"
                  ? {
                      type: "number",
                      domain: yAxisDomain as AxisDomain,
                      tickFormatter:
                        type === "percent" ? valueToPercent : valueFormatter,
                      allowDecimals: allowDecimals,
                    }
                  : {
                      dataKey: index,
                      ticks: startEndOnly
                        ? //@ts-ignore
                          [data[0][index], data[data.length - 1][index]]
                        : undefined,
                      type: "category",
                      interval: "equidistantPreserveStart",
                    })}
              >
                {yAxisLabel && (
                  <Label
                    position="insideLeft"
                    style={{ textAnchor: "middle" }}
                    angle={-90}
                    offset={-15}
                    className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                  >
                    {yAxisLabel}
                  </Label>
                )}
              </YAxis>
              <Tooltip
                shared={false}
                wrapperStyle={{ outline: "none" }}
                isAnimationActive={true}
                animationDuration={100}
                cursor={{ fill: "transparent", opacity: "0.15" }}
                offset={20}
                content={({ active, payload, label }) => {
                  const cleanPayload: TooltipProps<PayloadItem>["payload"] =
                    payload
                      ? payload.map((item: any) => ({
                          category: item.dataKey,
                          value: item.value,
                          index: item.payload[index],
                          color: categoryColors.get(
                            item.dataKey,
                          ) as AvailableChartColorsKeys,
                          type: item.type,
                          payload: item.payload,
                          id: data.findIndex((d) => d === item.payload),
                        }))
                      : [];

                  if (
                    tooltipCallback &&
                    (active !== prevActiveRef.current ||
                      label !== prevLabelRef.current)
                  ) {
                    tooltipCallback({ active, payload: cleanPayload, label });
                    prevActiveRef.current = active;
                    prevLabelRef.current = label;
                  }

                  return showTooltip && active ? (
                    CustomTooltip ? (
                      <CustomTooltip
                        active={active}
                        payload={cleanPayload}
                        label={label}
                      />
                    ) : (
                      <ChartTooltip
                        active={active}
                        payload={cleanPayload}
                        label={label}
                        valueFormatter={valueFormatter}
                        mode={mode}
                        formatXAxisTick={formatXAxisTick}
                      />
                    )
                  ) : null;
                }}
              />
              {showLegend ? (
                <RechartsLegend
                  verticalAlign="bottom"
                  height={legendHeight}
                  content={({ payload }) => {
                    return (
                      <ChartLegend
                        payload={payload || []}
                        categoryColors={categoryColors}
                        setLegendHeight={setLegendHeight}
                        activeLegend={activeLegend}
                        enableLegendSlider={enableLegendSlider}
                        legendPosition={legendPosition}
                        yAxisWidth={yAxisWidth}
                        rotateXAxisTick={rotateXAxisTick}
                        // onClick={
                        //   hasOnValueChange
                        //     ? (clickedLegendItem: string) =>
                        //         onCategoryClick(clickedLegendItem)
                        //     : undefined
                        // }
                      />
                    );
                  }}
                />
              ) : null}
              {categories.map((category) => {
                const activeClass = getActiveClass(
                  categoryColors.get(category) as AvailableChartColorsKeys,
                );

                const inactiveClass = getInactiveClass(
                  categoryColors.get(category) as AvailableChartColorsKeys,
                );

                return (
                  <Bar
                    className={cn(
                      activeBar ? inactiveClass : activeClass,
                      onValueChange ? "cursor-pointer" : "",
                    )}
                    key={category}
                    name={category}
                    type="linear"
                    dataKey={category}
                    stackId={stacked ? "stack" : undefined}
                    isAnimationActive={true}
                    animationDuration={1000}
                    fill={""}
                    shape={(props: BarProps) => (
                      <BarShape
                        props={props as ShapeProps<PayloadItem>}
                        activeBar={activeBar}
                        activeLegend={activeLegend}
                        layout={layout}
                        mode={mode}
                        colorKey={
                          categoryColors.get(
                            category,
                          ) as AvailableChartColorsKeys
                        }
                        borderRadius={borderRadius}
                      />
                    )}
                    onClick={onBarClick}
                    maxBarSize={30}
                  />
                );
              })}
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
      );
    },
  ),
);

BarChart.displayName = "BarChart";

export { BarChart };
export default BarChart;
