import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import { JollyTextField } from "@/components/ui/textfield";
import { cn } from "@/lib/utils";
import type { Icon } from "@phosphor-icons/react";
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

interface EditableFieldProps {
  fieldName: string;
  initialFieldValue?: string;
  onSave: (newValue: string) => boolean;
  className?: string;
  nonEditStyles?: string;
  maxLines?: number;
  nonHoverClick?: () => void;
  children?: React.ReactNode;
  LeftIcon?: Icon;
  placeholderText?: string;
  ClearIcon?: Icon;
}

interface EditorActionsProps {
  onSave: () => void;
  onCancel: () => void;
  offsetHeight: number;
}

const EditorActions = memo(
  ({ onSave, onCancel, offsetHeight }: EditorActionsProps) => {
    return (
      <div
        className="absolute border shadow-md rounded bg-white border-b border-b-border py-3 px-2 rounded-2 flex flex-row-reverse justify-between w-full z-50"
        style={{ top: offsetHeight + 4 }}
      >
        <div className="flex flex-row-reverse gap-2">
          <ButtonGroup
            primaryLabel="Save"
            primaryAction={onSave}
            primaryDataAction="save"
            secondaryLabel="Cancel"
            secondaryAction={onCancel}
            secondaryDataAction="cancel"
            size="sm"
          />
        </div>
      </div>
    );
  },
);

EditorActions.displayName = "EditorActions";

const EditableField: React.FC<EditableFieldProps> = ({
  initialFieldValue = "",
  onSave,
  className = "",
  nonEditStyles = "",
  maxLines = 1,
  nonHoverClick,
  LeftIcon,
  children,
  placeholderText,
  ClearIcon,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFieldValue, setEditedFieldValue] = useState(initialFieldValue);
  const [isHovered, setIsHovered] = useState(false);
  const divHeight = useRef<number>(0);

  const divRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0);

  const updateTextAreaHeight = useCallback(() => {
    if (textAreaRef.current) {
      setTextAreaHeight(textAreaRef.current.offsetHeight);
    }
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(updateTextAreaHeight);

    if (textAreaRef.current) {
      resizeObserver.observe(textAreaRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isEditing, updateTextAreaHeight]);

  const handleEditClick = useCallback(() => {
    divHeight.current = divRef.current?.offsetHeight ?? 0;
    if (divRef.current) {
      const initialHeight = divRef.current.offsetHeight;
      setTextAreaHeight(initialHeight);
    }
    setIsEditing(true);
    setIsHovered(false);
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
        const length = textAreaRef.current.value.length;
        textAreaRef.current.setSelectionRange(length, length);
      }
      updateTextAreaHeight();
    }, 0);
  }, [updateTextAreaHeight]);

  const handleFieldChange = useCallback((value: string) => {
    setEditedFieldValue(value);
  }, []);

  const handleFieldSave = useCallback(() => {
    const saveSuccess = onSave(editedFieldValue);

    if (saveSuccess) {
      setIsEditing(false);
    }
  }, [editedFieldValue, onSave]);

  const handleFieldCancel = useCallback(() => {
    setIsEditing(false);
    setEditedFieldValue(initialFieldValue);
  }, [initialFieldValue]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<Element>) => {
      const relatedTarget = e.relatedTarget as HTMLElement;
      if (
        relatedTarget &&
        ["save", "cancel"].includes(relatedTarget?.dataset?.action ?? "")
      ) {
        return;
      }
      handleFieldSave();
    },
    [handleFieldSave],
  );

  const handleEditingKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        handleFieldCancel();
      } else if (e.key === "Enter" && !e.shiftKey) {
        handleFieldSave();
      }
    },
    [handleFieldCancel, handleFieldSave],
  );

  useEffect(() => {
    setEditedFieldValue(initialFieldValue);
  }, [initialFieldValue]);

  return (
    <div className="relative -mx-2">
      {isEditing ? (
        <div className="relative">
          {LeftIcon && (
            <LeftIcon
              className="text-gray-500 absolute top-1.5 left-2 z-50"
              size={16}
            />
          )}
          <div style={{ height: divHeight.current }} />
          <JollyTextField
            autoFocus={true}
            className={cn(
              "absolute top-0 left-0 w-full text-left z-40 overflow-hidden min-w-80 py-[5px] px-[7px]",
              className,
              "no-underline border-0 hover:no-underline data-[focused=true]:ring-1",
            )}
            style={{ height: divHeight.current }}
            value={editedFieldValue}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            onKeyDown={handleEditingKeyDown}
            aria-label="Edit Field Name. Press Enter to save, Escape to cancel"
            textArea={maxLines > 1}
            ref={textAreaRef}
          />
          {ClearIcon && editedFieldValue && (
            // biome-ignore lint/a11y/useKeyWithClickEvents: This span is used to clear the input field and prevent the blur event from firing prematurely.
            <span
              className="absolute top-1.5 right-2 w-4 h-4 bg-slate-300 rounded-full p-0.5 cursor-pointer z-40"
              onMouseDown={(e) => e.preventDefault()} // Prevent blur event
              onClick={() => setEditedFieldValue("")}
            >
              <ClearIcon size={16} className="text-white w-3 h-3" />
            </span>
          )}
          <EditorActions
            onSave={handleFieldSave}
            onCancel={handleFieldCancel}
            offsetHeight={textAreaHeight}
          />
        </div>
      ) : (
        <div
          className={cn(
            "relative min-h-6 py-1.5 px-2 overflow-hidden group rounded",
            isHovered ? "bg-slate-200/20" : nonEditStyles,
            className,
          )}
          role={nonHoverClick && "button"}
          ref={divRef}
          onClick={() => !isHovered && nonHoverClick?.()}
          onKeyDown={(e) => {
            if (!isHovered && (e.key === "Enter" || e.key === " ")) {
              nonHoverClick?.();
            }
          }}
        >
          {LeftIcon && (
            <LeftIcon
              className="text-gray-500 absolute top-1.5 left-2"
              size={16}
            />
          )}
          {children ? (
            children
          ) : (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <p
              className={cn(
                "text-ellipsis overflow-hidden",
                maxLines === 1 ? "line-clamp-1" : `line-clamp-${maxLines}`,
              )}
              onClick={handleEditClick}
            >
              {editedFieldValue || placeholderText}
            </p>
          )}
          <EditButton
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="bottom-1 right-1"
          />
        </div>
      )}
    </div>
  );
};

export default memo(EditableField);
