import { type VariantProps, cva } from "class-variance-authority";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";
import { CaretDown, CaretUpDown } from "@phosphor-icons/react";
import React, {
  useState,
  useCallback,
  type KeyboardEvent,
  useEffect,
} from "react";

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors transition-duration-100",
    /* Disabled */
    "data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
    /* Focus Visible */
    "data-[focus-visible]:outline-none data-[focus-visible]:ring-2 data-[focus-visible]:ring-ring data-[focus-visible]:ring-offset-2 data-[focus-visible]:ring-blue-750",
    /* Resets */
    "!focus-visible:outline-none focus:outline-none",
    "cursor-pointer",
  ],
  {
    variants: {
      variant: {
        input: "rounded-md bg-slate-400/15 text-slate-500",
        default:
          "bg-violet-800 text-white data-[hovered]:bg-violet-700 data-[pressed]:bg-violet-900",
        destructive: "bg-red-800 text-red-50 data-[hovered]:bg-red-800/90",
        outline:
          "border border-gray-200 bg-background hover:bg-slate-400/5 active:bg-slate-500/10 shadow-sm text-slate-800",
        secondary: "bg-slate-100 text-slate-700",
        ghost:
          "data-[hovered]:bg-accent data-[hovered]:text-accent-foreground text-gray-900 tracking-tight",
        link: "text-primary underline-offset-4 data-[hovered]:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        input:
          "py-1 px-2 absolute h-6 top-1/2 -translate-y-1/2 absolute right-2.5 text-xs",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "size-9",
        xsIcon: "size-5",
        none: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {
  type?: "button" | "submit" | "reset";
}

export type ButtonSizeProps = VariantProps<typeof buttonVariants>["size"];

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, type = "button", ...props }, ref) => {
    return (
      <AriaButton
        ref={ref}
        type={type}
        className={composeRenderProps(className, (className) =>
          cn(
            buttonVariants({
              variant,
              size,
            }),
            className,
          ),
        )}
        {...props}
      />
    );
  },
);

interface DropdownButtonProps extends ButtonProps {
  ariaLabel: string;
  icon?: React.ReactNode;
  label: string;
  customLabel?: string;
  dropdownVariant?: "primary" | "secondary";
}

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      ariaLabel,
      icon,
      label,
      customLabel,
      dropdownVariant = "primary",
      ...props
    },
    ref,
  ) => {
    const isSecondary = dropdownVariant === "secondary";
    return (
      <Button
        ref={ref}
        aria-label={ariaLabel}
        size="sm"
        variant="outline"
        className={cn(
          "px-1",
          isSecondary ? "text-grey-900 text-xs font-normal" : "text-slate-500",
        )}
        {...props}
      >
        {icon}
        <span className="mr-1" />
        <span className={cn(isSecondary ? "text-gray-900" : "text-slate-600")}>
          {customLabel || label}
        </span>
        {isSecondary ? (
          <CaretDown className="ml-1  shrink-0 fill-gray-600" size={16} />
        ) : (
          <CaretUpDown className="ml-1 h-5 w-5 shrink-0" />
        )}
      </Button>
    );
  },
);

// Button Group
interface ButtonConfig {
  /**
   * label: The label of the button
   */
  label: string;
  /**
   * onClick: The function to be called when the button is clicked
   */
  onClick: () => void;
}

interface ButtonGroupProps {
  /**
   * buttonConfig: An array of button configurations consisting of the label and onClick function
   */
  buttonConfig: ButtonConfig[];
  /**
   * defaultSelectedIndex: The index of the button to be selected by default
   */
  defaultSelectedIndex?: number;
  /**
   * className: The class name to be applied to the button group
   */
  className?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttonConfig,
  className,
  defaultSelectedIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    defaultSelectedIndex ?? 0,
  );

  useEffect(() => {
    if (defaultSelectedIndex !== undefined) {
      setSelectedIndex(defaultSelectedIndex);
    }
  }, [defaultSelectedIndex]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLFieldSetElement>) => {
      if (event.key === "ArrowRight") {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % buttonConfig.length);
      } else if (event.key === "ArrowLeft") {
        setSelectedIndex((prevIndex) =>
          prevIndex === 0 ? buttonConfig.length - 1 : prevIndex - 1,
        );
      }
    },
    [buttonConfig.length],
  );

  return (
    <fieldset
      aria-label="Button group"
      onKeyDown={handleKeyDown}
      className={cn(
        "flex text-[10px] tracking-wide font-normal cursor-pointer shadow-sm rounded-lg",
        className,
      )}
    >
      {buttonConfig.map((button, index) => (
        <AriaButton
          key={button.label}
          onPress={() => {
            button.onClick();
            setSelectedIndex(index);
          }}
          className={cn("border px-3 py-1 min-h-[30px]", {
            "rounded-l-md border-r-0": index === 0,
            "rounded-r-md border-l-0": index === buttonConfig.length - 1,
            "bg-violet-100 font-medium hover:bg-violet-200":
              index === selectedIndex,
            "bg-white font-normal hover:bg-gray-100": index !== selectedIndex,
          })}
          onFocus={() => setSelectedIndex(index)}
        >
          {button.label}
        </AriaButton>
      ))}
    </fieldset>
  );
};

export { Button, DropdownButton, buttonVariants, ButtonGroup };
export type { ButtonProps, ButtonGroupProps };
