import type { FeedDataTermsType, FeedWithAugTypes } from "@/data-access/news";
import { derive } from "@/lib/derive-zustand";
import { isSameDay, isWithinInterval, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useStore } from "zustand";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import type { DerivedFilterItem } from "./feed.slice";
import { FilterCategory } from "./feed.types";

/**
 * Checks if the feed item's date matches the feed volume selected date.
 *
 * @param itemDate - The date from the item (ISO string)
 * @param startOfPeriod - The selected date (Date object)
 * @param endOfPeriod - The selected date (Date object)
 * @returns {boolean} True if the dates match, false otherwise
 */
export const isWithinRange = (
  itemDate: string | undefined | null,
  startOfPeriod: string | null,
  endOfPeriod: string | null,
): boolean => {
  if (!itemDate || !startOfPeriod) {
    return false;
  }

  const parsedItemDate = toZonedTime(parseISO(itemDate), "UTC");
  const parsedStartDate = toZonedTime(parseISO(startOfPeriod), "UTC");

  // If endOfPeriod is not provided, check if the dates are the same
  if (!endOfPeriod) {
    return isSameDay(parsedItemDate, parsedStartDate);
  }

  const parsedEndDate = toZonedTime(parseISO(endOfPeriod), "UTC");
  return isWithinInterval(parsedItemDate, {
    start: parsedStartDate,
    end: parsedEndDate,
  });
};

// Article-related selectors
const deriveArticleCount = derive<number>((get) => {
  const { feedItems, removalSet, totalCount } = get(useFeedStore);
  const removedItemsCount = feedItems.filter((item) =>
    removalSet.has(item.id ?? -1),
  ).length;
  return (totalCount ?? feedItems.length) - removedItemsCount;
});

const deriveArticleIds = derive<number[]>((get) => {
  const { feedItems } = get(useFeedStore);
  return feedItems
    .map((item) => item.id)
    .filter((id): id is number => id !== undefined);
});

// Feed metadata selectors
const deriveFeedName = derive<string>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.name ?? "") : "";
});

const deriveFeedTerms = derive<FeedDataTermsType[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.terms ?? []) : [];
});

const deriveKnownTags = derive<string[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed
    ? (currentFeed.knownTags?.map((tag) => tag.tag) ?? [])
    : [];
});

// Filtering and search selectors
const deriveFilterOrSearch = derive<boolean>((get) => {
  const { searchTerms, selected } = get(useFeedParamsStore);
  const { categoryAppliedFilters } = get(useFeedStore);
  return (
    (categoryAppliedFilters
      ? Object.values(categoryAppliedFilters).some(
          (filter) => (selected[filter?.name ?? ""]?.length ?? 0) > 0,
        )
      : false) || searchTerms?.length > 0
  );
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const { feedItems, removalSet, filteredFeedIds, filterFeedIdSearch } =
    get(useFeedStore);

  // First, filter out removed and filtered items
  const filteredItems = feedItems.filter(
    (item) =>
      !removalSet.has(+(item.id ?? -1)) &&
      filteredFeedIds.has(+(item.id ?? -1)),
  );

  // Apply search and category filters
  const filteredAndSearched = filteredItems.map((item) => {
    const searchHit = filterFeedIdSearch.find(([id]) => item.id === id)?.[1];
    return {
      ...item,
      searchHit: searchHit ?? undefined,
    } as Partial<FeedWithAugTypes>;
  });

  return filteredAndSearched;
});

const deriveFilteredItemsTotal = derive<number>((get) => {
  const filteredItems = get(deriveFilteredFeedItems);
  const { currentCount, removalSet } = get(useFeedStore);
  const removedItemsCount = filteredItems.filter((item) =>
    removalSet.has(item.id ?? -1),
  ).length;
  return currentCount ?? filteredItems.length - removedItemsCount;
});

const createFilterItem = (
  categoryKey: string,
  filter: string,
  count: Set<number>,
  selectedSet: Set<string>,
  removalSet: Set<number>,
  filterIdsSet: Set<number>,
): DerivedFilterItem => {
  const totalCount = count.size;
  const filteredCount = getFilteredCount(count, removalSet, filterIdsSet);

  return {
    id: categoryKey + filter,
    value: filter,
    totalCount,
    count: filteredCount,
    selected: selectedSet.has(filter),
  };
};

const getFilteredCount = (
  relevantFilterIdSet: Set<number>,
  removalSet: Set<number>,
  filterIdsSet: Set<number>,
): number => {
  if (filterIdsSet.size === 0) return 0;
  if (!(relevantFilterIdSet instanceof Set)) {
    return 0;
  }
  const filteredSet = relevantFilterIdSet.intersection(filterIdsSet);
  return filteredSet.difference(removalSet).size;
};

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const {
    categoryAppliedFilters,
    fetchingFilters,
    removalSet,
    filteredFeedIds: filterIdsSet,
  } = get(useFeedStore);
  const { selected } = get(useFeedParamsStore);

  const categoryFilters = new Map<string, DerivedFilterItem[]>();

  for (const categoryKey in categoryAppliedFilters) {
    const categoryValue = categoryAppliedFilters[categoryKey];
    if (!categoryValue) continue;

    const counts = categoryValue.itemCounts;
    const selectedSet = new Set<string>(selected[categoryKey] || []);
    const filters: DerivedFilterItem[] = [];

    const filterEntries = counts ? Object.entries(counts) : [];
    for (const [filter, countSet] of filterEntries) {
      if (!filter || !countSet) continue;

      let filterItem: DerivedFilterItem;
      if (fetchingFilters) {
        filterItem = {
          id: `${categoryKey}${filter}`,
          value: filter,
          totalCount: 0,
          count: 0,
          selected: false,
        };
      } else {
        filterItem = createFilterItem(
          categoryKey,
          filter,
          countSet,
          selectedSet,
          removalSet,
          filterIdsSet,
        );
      }

      filters.push(filterItem);
    }

    // Optimize sorting for SOCIAL category
    if (
      categoryKey === FilterCategory.SOCIAL ||
      categoryKey === FilterCategory.READERSHIP
    ) {
      filters.sort((a, b) => {
        if (a.value === "0") return 1;
        if (b.value === "0") return -1;
        return 0; // maintain original order for non-zero values
      });
    }

    categoryFilters.set(categoryKey, filters);
  }

  const result: {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[] = [];

  for (const categoryKey in categoryAppliedFilters) {
    const categoryValue = categoryAppliedFilters[categoryKey];
    if (!categoryValue) continue;

    result.push({
      key: categoryKey,
      title: categoryValue.name ?? categoryKey,
      filters: categoryFilters.get(categoryKey) ?? [],
      sorted: categoryValue.sorted ?? true,
    });
  }

  return result;
});

// Analytics selectors
/**
 * Derives feed-related percentage based on the current feed state.
 * @param {function} get - Function to retrieve state slices from the store.
 * @returns {number | null} Percentage of filtered stories relative to total stories.
 */
const deriveFeedPercentage = derive<number | null>((get) => {
  const { totalCount, currentCount } = get(useFeedStore);
  let currentItems = currentCount;
  if (!currentItems) {
    currentItems = get(deriveFilteredFeedItems).length;
  }
  if (!totalCount) return null;
  return Math.round((currentItems / totalCount) * 100);
});

// Hook exports grouped by responsibility
// Article hooks
const useArticleCount = () => useStore(deriveArticleCount);
const useArticleIds = () => useStore(deriveArticleIds);

// Feed metadata hooks
const useFeedName = () => useStore(deriveFeedName);
const useFeedTerms = () => useStore(deriveFeedTerms);
const useKnownTags = () => useStore(deriveKnownTags);

// Filtering and search hooks
const useIsFilterOrSearch = () => useStore(deriveFilterOrSearch);
const useFilteredFeedItems = () => useStore(deriveFilteredFeedItems);
const useFilterGroups = () => useStore(deriveFilterGroups);
const useFilteredItemsTotal = () => useStore(deriveFilteredItemsTotal);

// Analytics hooks
const useFeedPercentage = () => useStore(deriveFeedPercentage);

// Exports grouped by responsibility
export {
  // Article-related exports
  useArticleCount,
  useArticleIds,
  // Feed metadata exports
  useFeedName,
  useFeedTerms,
  useKnownTags,
  // Filtering and search exports
  useIsFilterOrSearch,
  useFilteredFeedItems,
  useFilterGroups,
  useFilteredItemsTotal,
  // Analytics exports
  useFeedPercentage,
};
