import { cn } from "@/lib/utils";
import { ChartBar } from "@phosphor-icons/react";

// region NoDataView
const NoDataView = ({ className }: { className: string }) => {
  return (
    <div className={cn("h-80 w-full relative", className)}>
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g
              key={`h-grid-${
                // biome-ignore lint/suspicious/noArrayIndexKey: <no unique keys as it is a placeholder for no data>
                index
              }`}
            >
              {/* Static Text Label */}
              <text
                x="5%" // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="middle"
              >
                0
              </text>
              {/* Grid Line */}
              <line
                x1="7%"
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400">
        <ChartBar size={20} />
        <p className="text-[10px]">Oops! Graph data failed to load</p>
      </div>
    </div>
  );
};

//# region ChartSkeleton
const ChartSkeleton = ({ className }: { className: string }) => {
  return (
    <div className={cn("h-80 w-full relative", className)}>
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g
              key={`h-grid-${
                // biome-ignore lint/suspicious/noArrayIndexKey: <no unique keys as it is a placeholder for no data>
                index
              }`}
            >
              {/* Static Text Label */}
              <text
                x="5%" // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="middle"
              >
                0
              </text>
              {/* Grid Line */}
              <line
                x1="7%"
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      <div className={"flex absolute inset-0 justify-center items-center"}>
        <div className="flex items-end space-x-1 h-20 ">
          <div className="bg-gray-300 animate-pulse h-8 w-2 rounded-sm" />
          <div className="bg-gray-300  animate-pulse h-10 w-2 rounded-sm" />
          <div className="bg-gray-300 animate-pulse h-16 w-2 rounded-sm" />
          <div className="bg-gray-300 animate-pulse h-12 w-2 rounded-sm" />
        </div>
      </div>
    </div>
  );
};

export { NoDataView, ChartSkeleton };
