import type { SortOptions } from "@/types/shared";
import type { StateCreator } from "zustand";
import { FilterCategory } from "./feed.types";

export type FilterAndSearchParams = {
  searchTerms: string;
  selected: Record<string, string[]>;
};

export type DateRangeParams = {
  filterDays: number;
  isCustomDaysRange: boolean;
  startDate?: Date;
  endDate?: Date;
  period: "day" | "week" | "month" | null;
};

export type ArticlesParams = {
  sortOrder: SortOptions;
};

const DEFAULT_FILTER_DAYS = 30;

export type ParamsStorageSlice = FilterAndSearchParams &
  ArticlesParams &
  DateRangeParams;

const createFeedParamsSlice: StateCreator<
  ParamsStorageSlice,
  [["zustand/devtools", never]],
  []
> = () => ({
  selected: {
    [FilterCategory.CATEGORY]: ["News", "Press Release", "Owned Media"],
  },
  searchTerms: "",
  filterDays: DEFAULT_FILTER_DAYS,
  isCustomDaysRange: false,
  period: "day",
  sortOrder: "DATE_DESC",
});

export default createFeedParamsSlice;
