import ConfirmationDialog from "@/components/common/ConfirmationDialog";
import Checkbox from "@/components/ui/forms/checkbox.tsx";
import type { DeleteFeedArticleParemeters } from "@/data-access/news";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../ui/forms/form";
import { useDeleteFeedArticle } from "./useFeedArticleActions.ts";

import { DialogOverlay } from "@/components/ui/dialog.tsx";
import { Input } from "@/components/ui/textfield.tsx";
// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName.ts";

const RemoveArticleFormSchema = z.object({
  notRelevant: z.boolean().optional(),
  notNews: z.boolean().optional(),
  other: z.boolean().optional(),
  otherReason: z.string().optional(),
});

export type FeedArticleRemoveArgs = Omit<
  DeleteFeedArticleParemeters,
  "feedArticleId" | "feedId"
>;

type FeedArticleRemoveProps = {
  isOpen: boolean;
  onClose: () => void;
  numberOfItemsToDelete: number;
};

const FeedArticleRemove = ({
  isOpen,
  onClose,
  numberOfItemsToDelete,
}: FeedArticleRemoveProps) => {
  const form = useForm({
    resolver: zodResolver(RemoveArticleFormSchema),
    defaultValues: {
      notRelevant: false,
      notNews: false,
      other: false,
      otherReason: "",
    },
  });
  const { watch, reset } = form;
  const isOtherSelected = useWatch({
    control: form.control,
    name: "other",
  });
  const { handleDeleteArticle } = useDeleteFeedArticle();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleOnDelete = (
    reasonToDelete: z.infer<typeof RemoveArticleFormSchema>,
  ) => {
    const selectedReasons: string[] = [];

    if (reasonToDelete.notRelevant) {
      selectedReasons.push("This is not relevant to the company");
    }

    if (reasonToDelete.notNews) {
      selectedReasons.push("This is not news/blog");
    }

    if (reasonToDelete.other && reasonToDelete.otherReason) {
      selectedReasons.push(reasonToDelete.otherReason);
    }

    handleDeleteArticle(selectedReasons.join(", "));
    handleClose();
  };

  const items = [
    { id: "notRelevant", label: "This is not relevant to the company" },
    { id: "notNews", label: "This is not news/blog" },
    { id: "other", label: "Other" },
  ] as const;

  return (
    <>
      <DialogOverlay isOpen={isOpen}>
        <ConfirmationDialog
          title={`Are you sure you want to delete ${addCommasToNumbers(
            numberOfItemsToDelete,
          )} ${pluralize(numberOfItemsToDelete, "article")}?`}
          onCancelClick={handleClose}
          submitText="Yes, delete"
          submittingText="Deleting"
          submitVariant="destructive"
          onSubmitClick={() => {
            handleOnDelete(form.getValues());
          }}
        >
          <p className={"text-sm pb-5"}>
            This will permanently remove all selected articles from your
            newsfeed.
          </p>
          <p className={"text-sm"}>
            We’d love to improve. Could you let us know what went wrong?
          </p>

          <Form {...form}>
            {items.map((item) => (
              <FormField
                control={form.control}
                key={item.id}
                name={item.id}
                render={({ field }) => (
                  <FormItem className="flex gap-2 items-center">
                    <FormControl>
                      <Checkbox
                        checked={watch(item.id)}
                        onCheckedChange={(checked) => field.onChange(checked)}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-normal">
                      {item.label}
                    </FormLabel>
                  </FormItem>
                )}
              />
            ))}
            <div className="w-full min-h-14">
              {isOtherSelected && (
                <FormField
                  control={form.control}
                  name="otherReason"
                  render={({ field }) => (
                    <FormItem>
                      <Input
                        {...field}
                        className="w-full"
                        placeholder={"Add a reason"}
                      />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </Form>
        </ConfirmationDialog>
      </DialogOverlay>
    </>
  );
};

export default FeedArticleRemove;
