import { CaretLeft } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { HandraiseLogoMark } from "@/assets/handraise";
import { AuthForm } from "@/components/auth/AuthForm";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import useAuth from "@/store/auth.slice";
import { z } from "zod";

const resetPasswordConfirmSchema = z
  .object({
    new_password1: z
      .string()
      .trim()
      .min(6, { message: "Password should be at least 6 characters long." })
      .max(50, { message: "Password should not exceed 50 characters." }),
    new_password2: z.string().trim(),
  })
  .refine((data) => data.new_password1 === data.new_password2, {
    message: "Passwords do not match",
    path: ["new_password2"],
  });

const ForgotPasswordConfirmScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordConfirm = useAuth((state) => state.resetPasswordConfirm);

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  useEffect(() => {
    if (!uid || !token) {
      navigate("/auth/forgot-password", { replace: true });
    }
  }, [uid, token, navigate]);

  const handleResetPassword = async (
    data: z.infer<typeof resetPasswordConfirmSchema>,
  ) => {
    try {
      await resetPasswordConfirm({
        uid,
        token,
        new_password1: data.new_password1,
        new_password2: data.new_password2,
      });
      navigate("/auth/login", { replace: true });
    } catch (error) {
      // Error handling is done in the auth slice
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-violet-50 to-white">
      <div className="absolute top-5 left-5">
        <Button
          variant="ghost"
          onPress={() => navigate("/auth/login")}
          className="flex items-center"
        >
          <CaretLeft className="mr-2" />
          Back to Login
        </Button>
      </div>

      <div
        className={cn(
          "w-full max-w-md px-8 pt-6 pb-8 mb-28",
          "bg-white/80 shadow-2xl rounded-xl",
          "border border-gray-100",
        )}
      >
        <div className="w-full flex items-center justify-center mb-6">
          <HandraiseLogoMark className="w-48" />
        </div>

        <h1 className="text-center text-2xl font-semibold text-gray-800 mb-2">
          Reset Password
        </h1>
        <p className="text-center text-gray-600 mb-6">
          Enter your new password
        </p>

        <AuthForm
          schema={resetPasswordConfirmSchema}
          onSubmit={handleResetPassword}
          fields={[
            {
              name: "new_password1",
              label: "New Password",
              type: "password",
            },
            {
              name: "new_password2",
              label: "Confirm New Password",
              type: "password",
            },
          ]}
          submitLabel="Reset Password"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordConfirmScreen;
