import Buzzsumo from "@/assets/svg/Buzzsumo.svg?react";
import Moz from "@/assets/svg/Moz.svg?react";
import Readership from "@/assets/svg/Readership.svg?react";
import { Gauge, Sparkle } from "@phosphor-icons/react";

export const colorKeysByStackedBy = {
  impact_score: [
    {
      active: "violet",
      inactive: "chart-inactive-amethyst-100",
    },
    {
      active: "chart-amethyst-100",
      inactive: "chart-inactive-amethyst-200",
    },
    {
      active: "chart-amethyst-200",
      inactive: "chart-inactive-amethyst-300",
    },
    {
      active: "chart-amethyst-300",
      inactive: "chart-inactive-amethyst-400",
    },
    {
      active: "chart-amethyst-400",
      inactive: "chart-inactive-amethyst-500",
    },
    {
      active: "chart-crimson-100",
      inactive: "chart-inactive-amethyst-600",
    },
    {
      active: "chart-crimson-150",
      inactive: "chart-inactive-crimson-100",
    },
    {
      active: "chart-crimson-200",
      inactive: "chart-inactive-crimson-100",
    },
    {
      active: "chart-crimson-300",
      inactive: "chart-inactive-crimson-100",
    },
    {
      active: "chart-crimson-400",
      inactive: "chart-inactive-crimson-100",
    },
  ],
  sentiment: [
    {
      active: "chart-sage-100",
      inactive: "chart-inactive-sage-100",
    },
    {
      active: "chart-graphite-100",
      inactive: "chart-inactive-graphite-100",
    },
    {
      active: "chart-crimson-500",
      inactive: "chart-inactive-crimson-400",
    },
  ],
  social: [
    {
      active: "chart-ocean-100",
      inactive: "chart-inactive-ocean-100",
    },
    {
      active: "chart-ocean-200",
      inactive: "chart-inactive-ocean-200",
    },
    {
      active: "chart-ocean-250",
      inactive: "chart-inactive-ocean-250",
    },
    {
      active: "chart-ocean-300",
      inactive: "chart-inactive-ocean-300",
    },
    {
      active: "chart-ocean-400",
      inactive: "chart-inactive-ocean-400",
    },
    {
      active: "chart-ocean-500",
      inactive: "chart-inactive-ocean-500",
    },
    {
      active: "chart-ocean-600",
      inactive: "chart-inactive-ocean-600",
    },
    {
      active: "chart-ocean-700",
      inactive: "chart-inactive-ocean-700",
    },
    {
      active: "chart-ocean-800",
      inactive: "chart-inactive-ocean-800",
    },
    {
      active: "chart-ocean-900",
      inactive: "chart-inactive-ocean-900",
    },
    {
      active: "chart-ocean-950",
      inactive: "chart-inactive-ocean-950",
    },
  ],
  tier: [
    {
      active: "chart-ocean-200",
      inactive: "chart-inactive-ocean-200",
    },
    {
      active: "chart-ocean-300",
      inactive: "chart-inactive-ocean-400",
    },
    {
      active: "chart-ocean-500",
      inactive: "chart-inactive-ocean-600",
    },
  ],
};

interface ChartStackedByOption {
  label: string;
  value: string | null;
  icon?: JSX.Element;
}

export const chartStackedByOptions: ChartStackedByOption[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Impact Score",
    value: "impact_score",
    icon: <Gauge className={"fill-violet-800"} />,
  },
  {
    label: "Prominence",
    value: "prominence",
  },
  {
    label: "Sentiment",
    value: "sentiment",
    icon: <Sparkle className={"fill-violet-800"} />,
  },
  {
    label: "Publication Tier",
    value: "tier",
  },
  {
    label: "Social Engagement",
    value: "social",
    icon: <Buzzsumo height={12} />,
  },
  {
    label: "Domain Authority",
    value: "domain_authority",
    icon: <Moz width={20} height={12} />,
  },
  {
    label: "Readership",
    value: "readership",
    icon: <Readership width={10} height={10} />,
  },
  {
    label: "Content Category",
    value: "content_category",
  },
];
