import { useFeedParamsStore } from "../useFeedStore";
import { updateFilteredIds } from "./filterSearch.actions";

export const updateSelectedFilters = (
  categoryKey: string,
  selected: string[],
) => {
  const { selected: originalSelected } = useFeedParamsStore.getState();

  if (!selected) return;

  useFeedParamsStore.setState(
    {
      selected: {
        ...originalSelected,
        [categoryKey]: selected,
      },
    },
    false,
    `[User] Filters: Updated ${categoryKey}`,
  );

  updateFilteredIds();
};
