import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";

import DefaultAvatar from "@/assets/images/default-avatar.png";
import { Button } from "@/components/ui/controls/button";
import { Separator } from "@/components/ui/controls/separator";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/forms/form";
import { Input } from "@/components/ui/forms/input";
import { setTmpPicture, startUpdateAccount } from "@/store/actions/settings";
import useAuth from "@/store/auth.slice";
import { Upload } from "@phosphor-icons/react";

const formSchema = z.object({
  firstName: z.string().min(1, { message: "First name is required." }),
  lastName: z.string().min(1, { message: "First name is required." }),
  email: z
    .string()
    .email("Please enter a valid email address.")
    .min(1, { message: "Email address is required." }),
});

const SettingsScreen = () => {
  const user = useAuth((state) => state.user);
  const { avatar } = user;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const dispatch: any = useDispatch();
  const [image, setImage] = useState((avatar as string) || "");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: user.first_name || "",
      lastName: user.last_name || "",
      email: user.email || "",
    },
  });

  const { watch } = form;
  const [firstName, lastName, email] = watch([
    "firstName",
    "lastName",
    "email",
  ]);

  useEffect(() => {
    dispatch(setTmpPicture(image));
  }, [image]);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    const reader = new FileReader();
    if (files && files.length > 0) {
      if (!files[0]) return;
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        setImage(e.target?.result as string);
        dispatch(startUpdateAccount({ avatar: e.target?.result as string }));
      };
    }
  };

  const handleUpdateUser = () => {
    dispatch(
      startUpdateAccount({
        first_name: firstName,
        last_name: lastName,
        email,
      }),
    );
  };

  return (
    <div className="settings-screen overflow-y-auto h-screen">
      <div className="w-[608px] mx-auto flex flex-col gap-6 mb-8">
        <div className="header pt-10 flex flex-col gap-2">
          <h1 className="text-slate-900 text-2xl font-serif">General</h1>
          <div className="text-slate-500 text-sm font-serif">
            Manage your Handraise profile
          </div>
        </div>
        <Separator />
        <Form {...form}>
          <div className="flex flex-col">
            <div className="flex flex-col gap-3 mb-4">
              <div className="">Profile photo</div>
              <div className="relative inline group w-[132px] h-[132px]">
                <div
                  className="w-[132px] h-[132px] block rounded-full bg-contain bg-center"
                  style={{ backgroundImage: `url(${image || DefaultAvatar})` }}
                />
                <label
                  className="hidden group-hover:inline-flex gap-2 items-center text-slate-800 justify-center absolute inset-0 cursor-pointer rounded-full bg-opacity-70 bg-slate-200"
                  htmlFor="avatar"
                >
                  <Upload />
                  <span>Upload</span>
                </label>
                <input
                  name="avatar"
                  id="avatar"
                  className="hidden"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex gap-8 justify-between">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your first name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your last name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="pt-0">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter email address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-between">
              <Button
                variant="secondary"
                type="button"
                onClick={handleUpdateUser}
              >
                Save changes
              </Button>
            </div>
          </div>
        </Form>
        <Separator />
        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-col gap-1 leading-normal">
            <h3 className="text-slate-900 font-bold text-sm">Password Reset</h3>
            <div className="text-slate-500 text-sm">
              You'll receive an email with a link to create a new password.
            </div>
          </div>
          <Button variant="secondary">Password Reset</Button>
        </div>
        <Separator />
        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-col gap-1 leading-normal">
            <h3 className="text-slate-900 font-bold text-sm">Danger zone</h3>
            <div className="text-slate-500 text-sm">
              Once an organization is deleted, it's irreversible. This action
              cannot be undone.
            </div>
          </div>
          <Button variant="destructive">Delete organization</Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
