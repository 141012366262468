import { isDevelopment } from "@/config";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import createUrlStorage from "./createUrlStorage";
import { type ArticlesSlice, createArticlesSlice } from "./news/articles.slice";
import { type FeedSlice, createFeedSlice } from "./news/feed.slice";
import createFeedParamsSlice, {
  type ParamsStorageSlice,
} from "./news/feedParams.slice.ts";
import {
  type FilterAndSearchSlice,
  createFilterSearchSlice,
} from "./news/filterSearch.slice";

const useFeedStore = create<FeedSlice & ArticlesSlice & FilterAndSearchSlice>()(
  devtools(
    (...a) => {
      const store = {
        ...createFeedSlice(...a),
        ...createArticlesSlice(...a),
        ...createFilterSearchSlice(...a),
      };
      return store;
    },
    {
      name: "feedStore",
      enabled: isDevelopment,
      store: "feed",
    },
  ),
);

const useFeedParamsStore = create<ParamsStorageSlice>()(
  persist(
    devtools(
      (...a) => {
        const store = {
          ...createFeedParamsSlice(...a),
        };
        return store;
      },
      {
        name: "feedParamsStore",
        enabled: isDevelopment,
        store: "feedParams",
      },
    ),
    {
      name: "fd",
      version: undefined,
      storage: createUrlStorage(),
    },
  ),
);

export default useFeedStore;
export { useFeedParamsStore };
