interface HandraiseLogoMarkProps {
  className?: string;
}

export const HandraiseH: React.FC<HandraiseLogoMarkProps> = ({ className }) => (
  <svg
    width="360"
    height="358"
    viewBox="0 0 360 358"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={`fill-slate-900 ${className}`}
    aria-label="Handraise"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M293.416 71.7521V287.054C293.416 326.03 302.612 333.475 335.457 336.541V352.306H195.756V336.541C229.039 333.475 236.484 325.592 236.484 287.054V203.846H98.9731V287.054C98.9731 326.468 107.732 333.475 139.701 336.541V352.306H0V336.541C34.1588 333.475 42.4796 325.154 42.4796 287.054V120.754C42.4796 81.778 33.283 74.3331 0 71.2676V55.5019H139.701V71.2676C106.856 74.3331 98.9731 82.2159 98.9731 120.754V182.825H236.484V71.7521C236.484 32.3381 228.164 18.8312 195.756 15.7656V0.000181838L335.457 0V15.7656C301.737 18.8312 293.416 33.6519 293.416 71.7521Z"
    />
  </svg>
);

export const HandraiseLogoMark: React.FC<HandraiseLogoMarkProps> = ({
  className,
}) => (
  <svg
    width="1957"
    viewBox="0 0 1957 358"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={`fill-slate-900 ${className}`}
    aria-label="Handraise"
  >
    <path d="M1885.91 310.703C1914.37 310.703 1931.45 300.63 1942.84 283.113L1956.41 290.12C1948.09 322.089 1920.5 357.562 1867.95 357.562C1806.2 357.562 1767.66 311.579 1767.66 248.078C1767.66 179.323 1804.45 128.96 1867.95 128.96C1922.25 128.96 1956.41 165.309 1956.41 227.495H1812.33C1815.84 281.361 1843.43 310.703 1885.91 310.703ZM1858.75 149.105C1830.29 149.105 1813.65 170.126 1811.9 208.226H1897.73C1897.73 173.192 1887.66 149.105 1858.75 149.105Z" />
    <path d="M1663.33 358C1639.68 358 1611.65 352.307 1589.76 336.103V280.923H1607.27C1615.16 316.834 1640.99 335.227 1670.77 335.227C1693.11 335.227 1710.19 325.155 1710.19 305.01C1710.19 290.12 1702.3 281.361 1684.35 274.792L1641.87 259.027C1612.09 248.954 1590.19 228.809 1590.19 197.278C1590.19 154.798 1625.67 128.96 1675.15 128.96C1697.93 128.96 1725.52 135.529 1742.16 143.85V193.775H1724.64C1717.19 167.061 1695.74 149.543 1667.71 149.543C1645.81 149.543 1632.24 160.492 1632.24 176.257C1632.24 190.709 1638.8 200.344 1657.2 206.913L1706.25 225.744C1734.71 236.254 1751.35 255.085 1751.35 285.741C1751.35 330.41 1716.32 358 1663.33 358Z" />
    <path d="M1518.32 104.435C1498.61 104.435 1484.16 89.5455 1484.16 70.2764C1484.16 51.4452 1498.61 36.5555 1518.32 36.5555C1538.03 36.5555 1552.48 51.4452 1552.48 70.2764C1552.48 89.5455 1538.03 104.435 1518.32 104.435ZM1580.51 336.978V352.306H1459.64V336.978C1486.79 335.226 1493.79 329.971 1493.79 307.199V190.708C1493.79 174.943 1487.66 168.812 1473.65 168.812C1469.27 168.812 1464.02 169.249 1457.45 170.125V154.798L1528.83 134.215H1545.91V306.323C1545.91 330.409 1554.67 335.226 1580.51 336.978Z" />
    <path d="M1448.74 312.893L1458.81 319.9C1450.49 345.3 1431.22 357.124 1409.32 357.124C1386.11 357.124 1369.91 344.424 1366.84 317.272H1363.78C1357.21 340.482 1334.87 357.562 1303.78 357.562C1268.31 357.562 1242.47 338.731 1242.47 301.068C1242.47 265.596 1269.18 242.823 1323.05 232.313L1364.65 223.992V212.168C1364.65 181.95 1349.76 164.871 1318.23 164.871C1290.2 164.871 1272.25 177.571 1262.61 199.468L1248.16 193.775C1256.04 159.616 1283.2 128.96 1336.19 128.96C1387.86 128.96 1416.77 157.864 1416.77 214.795V301.944C1416.77 315.082 1422.02 322.965 1432.53 322.965C1439.98 322.965 1445.23 319.024 1448.74 312.893ZM1330.06 327.344C1346.26 327.344 1358.08 318.148 1364.65 299.755V241.071L1344.51 245.013C1315.17 251.144 1297.21 265.158 1297.21 291.434C1297.21 313.331 1309.47 327.344 1330.06 327.344Z" />
    <path d="M1226.22 128.96C1231.03 128.96 1235.41 129.398 1238.92 130.712V187.643H1229.28C1224.46 184.578 1217.46 182.826 1208.7 182.826C1185.05 182.826 1161.84 193.775 1161.84 232.751V306.324C1161.84 331.724 1173.23 335.227 1206.95 336.979V352.307H1075.13V336.979C1102.28 334.789 1109.29 329.972 1109.29 307.199V188.519C1109.29 175.381 1103.59 169.688 1089.14 169.688C1085.2 169.688 1078.63 170.126 1072.94 170.564V155.674L1139.94 134.216H1156.58V173.63C1156.58 189.833 1155.71 209.978 1154.39 220.489L1157.46 220.926L1162.28 192.899C1168.85 150.419 1195.12 128.96 1226.22 128.96Z" />
    <path d="M1069.99 336.978V352.306H988.976V336.54C988.976 326.906 990.29 313.768 995.107 300.192L992.479 298.878C980.655 335.226 957.007 357.561 919.344 357.561C867.668 357.561 833.071 315.519 833.071 254.647C833.071 176.256 885.623 128.96 950 128.96C964.452 128.96 976.276 132.025 985.472 136.842V75.5316C985.472 59.3281 978.465 52.7591 963.138 52.7591C957.007 52.7591 949.124 54.0729 939.927 55.3867V41.3728L1022.26 19.4761H1037.59V310.264C1037.59 329.533 1046.35 335.226 1069.99 336.978ZM985.472 295.374V161.367C978.027 153.046 966.641 148.667 951.313 148.667C914.527 148.667 888.251 178.446 888.251 236.253C888.251 286.178 909.272 318.147 943.869 318.147C963.576 318.147 977.59 308.513 985.472 295.374Z" />
    <path d="M828.418 336.979V352.307H715.869V336.979C735.576 335.227 741.707 328.22 741.707 310.703V211.73C741.707 180.199 727.255 164.871 701.417 164.871C680.396 164.871 664.193 174.068 655.434 193.337V307.199C655.434 330.41 661.127 335.227 681.71 336.979V352.307H568.723V336.979C595.875 335.227 603.32 329.972 603.32 307.199V185.016C603.32 174.505 594.999 169.25 584.051 169.25C580.109 169.25 574.416 169.688 566.971 170.564V155.674L633.975 134.216H650.617V159.178C650.617 167.499 648.865 176.257 645.8 188.081L648.865 188.957C661.127 150.857 687.841 128.96 726.379 128.96C765.793 128.96 793.821 149.981 793.821 203.409V306.324C793.821 330.41 802.58 335.227 828.418 336.979Z" />
    <path d="M558.256 312.892L568.328 319.899C560.007 345.299 540.738 357.123 518.842 357.123C495.631 357.123 479.428 344.423 476.362 317.271H473.296C466.727 340.482 444.393 357.561 413.3 357.561C377.827 357.561 351.989 338.73 351.989 301.068C351.989 265.595 378.703 242.822 432.569 232.312L474.172 223.991V212.167C474.172 181.949 459.283 164.87 427.751 164.87C399.724 164.87 381.768 177.57 372.134 199.467L357.682 193.774C365.565 159.615 392.717 128.959 445.707 128.959C497.383 128.959 526.287 157.863 526.287 214.795V301.943C526.287 315.081 531.542 322.964 542.052 322.964C549.497 322.964 554.752 319.023 558.256 312.892ZM439.576 327.344C455.779 327.344 467.603 318.147 474.172 299.754V241.071L454.027 245.012C424.686 251.143 406.731 265.157 406.731 291.433C406.731 313.33 418.993 327.344 439.576 327.344Z" />
    <path d="M293.416 71.7521V287.054C293.416 326.03 302.612 333.475 335.457 336.541V352.306H195.756V336.541C229.039 333.475 236.484 325.592 236.484 287.054V203.846H98.9731V287.054C98.9731 326.468 107.732 333.475 139.701 336.541V352.306H0V336.541C34.1588 333.475 42.4796 325.154 42.4796 287.054V120.754C42.4796 81.778 33.283 74.3331 0 71.2676V55.5019H139.701V71.2676C106.856 74.3331 98.9731 82.2159 98.9731 120.754V182.825H236.484V71.7521C236.484 32.3381 228.164 18.8312 195.756 15.7656V0.000181838L335.457 0V15.7656C301.737 18.8312 293.416 33.6519 293.416 71.7521Z" />
  </svg>
);
