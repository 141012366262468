export enum FilterCategory {
  IMPACT_SCORE = "IMPACT_SCORE",
  PROMINENCE = "PROMINENCE",
  PUBLICATION_TIER = "TIER",
  SENTIMENT = "SENTIMENT",
  SOCIAL = "SOCIAL",
  READERSHIP = "READERSHIP",
  DOMAIN_AUTHORITY = "DOMAIN_AUTHORITY",
  CATEGORY = "CATEGORY",
  TOP_TOPICS = "Top Topics",
  PUBLISHER = "PUBLISHER",
  AUTHOR = "AUTHOR",
}
