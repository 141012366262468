import { ButtonGroup, DropdownButton } from "@/components/ui/button";
import { Menu, MenuItem, MenuPopover, MenuTrigger } from "@/components/ui/menu";
import { cn } from "@/lib/utils";
import { Check } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import type { Period } from "./useFeedVolumeGraphData";
import { chartStackedByOptions } from "./volumeHelpers";

interface FeedVolumeGraphHeaderProps {
  period: Period;
  resetView: () => void;
  setPeriod: (period: Period) => void;
  stackFeedVolumeBy: string | null;
  setStackFeedVolumeBy: (stackBy: string | null) => void;
}

const FeedVolumeGraphHeader = ({
  period,
  setPeriod,
  resetView,
  stackFeedVolumeBy,
  setStackFeedVolumeBy,
}: FeedVolumeGraphHeaderProps) => {
  const { feedId } = useParams<{ feedId: string }>();

  // button configurations
  const buttonConfig = [
    {
      label: "Day",
      onClick: () => {
        setPeriod("day");
        resetView();
      },
    },
    {
      label: "Week",
      onClick: () => {
        setPeriod("week");
        resetView();
      },
    },
    {
      label: "Month",
      onClick: () => {
        setPeriod("month");
        resetView();
      },
    },
  ];

  const getDefaultSelectedIndex = () => {
    if (period === "day") return 0;
    if (period === "week") return 1;
    if (period === "month") return 2;
    return 2;
  };

  const selectedStackByOption = chartStackedByOptions.find(
    (option) => option.value === stackFeedVolumeBy,
  );

  useEffect(() => {
    if (feedId) {
      setStackFeedVolumeBy(null);
    }
  }, [feedId]);

  return (
    <div className={"flex justify-end"}>
      <MenuTrigger>
        <DropdownButton
          ariaLabel={"Stack Charts By"}
          label={selectedStackByOption?.label || "All"}
          dropdownVariant={"secondary"}
        />
        <MenuPopover crossOffset={-12}>
          <Menu className={"bg-white w-[212px]"}>
            <MenuItem
              className={cn(
                "text-xs px-3.5 py-2.5",
                selectedStackByOption?.value === null && "font-semibold",
              )}
              onAction={() => setStackFeedVolumeBy(null)}
            >
              All Categories
              {selectedStackByOption?.value === null && (
                <Check className={"w-4 h-4 ml-auto"} />
              )}
            </MenuItem>
            <MenuItem
              isDisabled={true}
              className={
                "text-[10px] font-semibold border-t-[1.5px] rounded-none px-3.5 pt-3"
              }
            >
              Compare by
            </MenuItem>
            {chartStackedByOptions.slice(1).map((option) => (
              <MenuItem
                className={cn(
                  "text-xs px-3.5 py-2.5",
                  selectedStackByOption?.value === option.value &&
                    "font-semibold",
                )}
                key={option.value}
                onAction={() => {
                  setStackFeedVolumeBy(option.value);
                  resetView();
                }}
              >
                {option.label}
                {option.icon ?? option.icon}
                {selectedStackByOption?.value === option.value && (
                  <Check className={"w-4 h-4 ml-auto"} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </MenuPopover>
      </MenuTrigger>
      <ButtonGroup
        buttonConfig={buttonConfig}
        className={"ml-3"}
        defaultSelectedIndex={getDefaultSelectedIndex()}
      />
    </div>
  );
};
export default FeedVolumeGraphHeader;
