import { readFragment } from "@/data-access/graphql";
import type {
  DomainOrReadershipConfig,
  FeedConfigurationInputType,
  FeedWithAugTypes,
} from "@/data-access/news";
import {
  type TierFragmentType,
  tierConfigurationFragment,
  tierFragment,
} from "@/data-access/news/tier";

export const TIER_1 = "Tier 1";
export const TIER_2 = "Tier 2";
export const TIER_3 = "Tier 3";

function readConfigurationFragment(config: DomainOrReadershipConfig) {
  const settingEnable =
    "enableCustomTierScoring" in config.tenant
      ? config.tenant.enableCustomTierScoring
      : config.tenant.enableReadership;
  const settingTiers = readFragment(tierConfigurationFragment, config);
  return {
    enable: settingEnable,
    frag: readFragment(tierFragment, settingTiers.tiers),
  };
}

// Utility function to get upper bound for a specified tier
function getUpperBound(tiers: readonly TierFragmentType[], tierNumber: number) {
  return (
    tiers.find((tier) => tier.tier === tierNumber)?.upperBound || undefined
  );
}

export const setTierLevel = (
  article: Partial<FeedWithAugTypes>,
  data: FeedConfigurationInputType,
) => {
  // Read tier configuration
  const tierConfig = readConfigurationFragment(data.tierConfiguration);
  const tierReadershipConfig = readConfigurationFragment(
    data.readershipConfiguration,
  );

  // Initialize tier bounds with default values
  let tier2Max = 80;
  let tier3Max = 60;

  // Update bounds if enabled
  if (tierConfig.enable) {
    tier2Max = getUpperBound(tierConfig.frag, 2) || tier2Max;
    tier3Max = getUpperBound(tierConfig.frag, 3) || tier3Max;
  }

  if (tierReadershipConfig.enable) {
    tier2Max = getUpperBound(tierReadershipConfig.frag, 2) || tier2Max;
    tier3Max = getUpperBound(tierReadershipConfig.frag, 3) || tier3Max;
  }

  const { tier1Pub, tier2Pub, tier3Pub } = data;

  const getTierLevelFromPublisher = (publisherId: number) => {
    if (
      tier1Pub?.publishers.some((pub) => pub?.id && +pub.id === publisherId)
    ) {
      return TIER_1;
    }
    if (
      tier2Pub?.publishers.some((pub) => pub?.id && +pub.id === publisherId)
    ) {
      return TIER_2;
    }
    if (
      tier3Pub?.publishers.some((pub) => pub?.id && +pub.id === publisherId)
    ) {
      return TIER_3;
    }
    return null;
  };

  const getTierLevelFromDomainAuthority = (domainAuthority: number) => {
    if (!tierConfig.enable) return null;

    if (domainAuthority < tier3Max) {
      return TIER_3;
    }
    if (domainAuthority < tier2Max) {
      return TIER_2;
    }
    return TIER_1;
  };

  const getTierLevelFromReadership = (readerCount: number) => {
    if (!tierReadershipConfig.enable) return null;

    if (readerCount < tier3Max) {
      return TIER_3;
    }
    if (readerCount < tier2Max) {
      return TIER_2;
    }
    return TIER_1;
  };

  const getTierPriority = (tier: string | null | false) => {
    switch (tier) {
      case TIER_1:
        return 1;
      case TIER_2:
        return 2;
      case TIER_3:
        return 3;
      default:
        return 3;
    }
  };

  const getTierLevel = (
    domainAuthority: number,
    publisherId: number,
    readerCount: number, // Added reader count parameter
  ) => {
    const tierFromPublisher = getTierLevelFromPublisher(publisherId);
    const tierFromDomainAuthority =
      getTierLevelFromDomainAuthority(domainAuthority);
    const tierFromReadership = getTierLevelFromReadership(readerCount);

    // Determine the final tier by considering all factors and enabled configurations
    const tiers: Array<string | null> = [
      tierFromDomainAuthority,
      tierFromReadership,
      tierFromPublisher,
    ].filter(Boolean) as Array<string>;

    if (tiers.length === 0) {
      return TIER_3;
    }

    // Find tier with the highest priority (lowest numerical value)
    const finalTier = tiers.reduce((prev, curr) =>
      getTierPriority(curr) < getTierPriority(prev) ? curr : prev,
    );

    return finalTier ?? TIER_3;
  };

  //set({ tier2Max, tier3Max });

  const processItem = (item: Partial<FeedWithAugTypes>) => {
    if (!item) return item;

    return {
      ...item,
      tierLevel: getTierLevel(
        item.maxDomainAuthority ?? 0,
        item.articlePublisher?.id ?? -1,
        item.articleReadership ?? 500,
      ),
    };
  };

  return processItem(article);
};
