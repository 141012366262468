export const DOMAIN_AUTHORITY_DESCRIPTION =
  "Domain Authority (DA) is a score developed by Moz that predicts a website's likelihood of ranking.";

export const ENGAGEMENT_DESCRIPTION =
  "A measure of how much an article has been shared, commented on, and reacted to on social media.";

export const ARTICLE_READERSHIP_DESCRIPTION =
  "Estimated number of visitors to this domain";

export const EMPTY_SUMMARY_INDICATOR = "\n";
