import useAuth from "@/store/auth.slice";
import useFeedStore from "@/store/useFeedStore";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import type React from "react";
import { useEffect } from "react";
import { gb } from ".";

export const FeatureManagementProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const user = useAuth((state) => state.user);
  const loggedIn = useAuth((state) => state.loggedIn);
  const isEmployee = useAuth((state) => state.isEmployee);
  const feedId = useFeedStore((state) => state.feedId);

  useEffect(() => {
    const attributes = {
      id: user?.id,
      loggedIn,
      email: user?.email,
      company: user?.default_tenant?.slug,
      employee: isEmployee,
      deviceId: feedId,
    };

    gb.setAttributes(attributes);
  }, [
    user?.id,
    loggedIn,
    user?.email,
    user?.default_tenant?.slug,
    isEmployee,
    feedId,
  ]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
