import { useAppNavigation } from "@/lib/navigation";
import { cn } from "@/lib/utils";
import { CaretDown, CaretRight, type Icon } from "@phosphor-icons/react";
import { createElement, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "../ui/controls/button";

type ItemType = { id: string | number; name: string }[];

interface DropdownListProps {
  title: "Newsfeeds" | "Campaigns";
  items: ItemType;
  icon: Icon;
  basePath: string;
  open?: boolean;
  collapsed?: boolean;
}

const DropdownList: React.FC<DropdownListProps> = ({
  title,
  items,
  icon,
  basePath,
  open = false,
  collapsed = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const { feedId } = useParams();
  useEffect(() => {
    if (!open) return;
    setIsOpen(open);
  }, [open]);

  const { navigateToFeed } = useAppNavigation();
  const navStyle = cn("flex items-center h-full cursor-pointer");

  const childNavStyle = ({ isActive }: { isActive: boolean }) =>
    cn(
      "flex items-center pl-6 pr-2 py-1 h-8 rounded cursor-pointer",
      "transition-colors text-slate-900",
      "hover:bg-slate-200",
      "truncate",
      "items-right",
      "ml-auto",
      isActive && "bg-slate-200/60 font-semibold text-slate-800",
    );

  const dropdownOpen = !collapsed && isOpen;
  const match = useMatch({ path: basePath, end: false });

  const iconSize = collapsed ? 20 : 16;
  const iconClass = collapsed && match ? "fill-slate-700" : "fill-slate-700";
  const iconWeight = collapsed && match ? "regular" : "regular";

  const handleClick = (feedId: string | number | null) => {
    if (basePath === "/newsfeeds") {
      navigateToFeed(feedId);
    }
  };

  const handleDoubleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div
        className={cn(
          "flex items-center pr-2 rounded transition-colors text-slate-900",
          !collapsed && match && "bg-slate-200 font-semibold text-slate-800",
          match && "bg-slate-200/75",
          collapsed ? "hover:bg-slate-200/75 h-10" : "hover:bg-slate-200 h-8",
        )}
      >
        {!collapsed &&
          (items.length > 0 ? (
            <Button
              size="icon"
              variant="ghost"
              onClick={() => setIsOpen(!isOpen)}
              className="p-0 pl-1 w-6 pr-0.5"
            >
              {dropdownOpen ? (
                <CaretDown size={16} />
              ) : (
                <CaretRight size={16} />
              )}
            </Button>
          ) : (
            <div className="w-6 pl-1 pr-0.5" />
          ))}
        <div
          className={navStyle}
          onClick={() => handleClick(null)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              handleClick(null);
            }
          }}
          onDoubleClick={handleDoubleClick}
          role="menu"
        >
          <div
            className={cn(
              "flex items-center",
              !collapsed ? "pl-0.5" : "pl-2.5",
            )}
          >
            {icon &&
              createElement(icon, {
                size: iconSize,
                className: iconClass,
                weight: iconWeight,
              })}
            {!collapsed && <span className="ml-2">{title}</span>}
          </div>
        </div>
      </div>
      {dropdownOpen && (
        <div className="flex flex-col gap-0.5 w-full">
          {items.map((item) => (
            <div
              key={item.id}
              className={childNavStyle({
                isActive: feedId === item.id.toString(),
              })}
              onClick={() => handleClick(item.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  handleClick(item.id);
                }
              }}
              role="menuitem"
              tabIndex={0}
            >
              <span className="ml-2 truncate" title={item.name}>
                {item.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownList;
