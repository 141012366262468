import { Button } from "@/components/ui/button";
import { EditButton } from "@/components/ui/molecules/EditButton";
import PublisherSearchDropdown from "@/components/ui/molecules/PublisherSearchDropdown";
import { toast } from "@/components/ui/toast";
import type { ArticlePublisherType } from "@/data-access/news";
import { usePublisherSearch } from "@/hooks/usePublisherSearch";
import { cn } from "@/lib/utils";
import { updateArticle } from "@/store/news/articles.actions";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import EditableDate from "../shared/EditableDate";
import EditableField from "../shared/EditableField";
import { formattedDateToUTC } from "../shared/utils";

interface ArticleHeaderProps {
  articleId: number;
  headline: string;
  overriddenHeadline?: string;
  articlePublisher: ArticlePublisherType;
  overriddenArticlePublisher?: ArticlePublisherType;
  articleDate?: string | null;
  articleUrl?: string | null;
  handleHeadlineClick?: () => void;
  onHeadlineChange?: (newHeadline: string) => void;
}

const PublisherButton = memo(
  ({
    logoUrl,
    name,
    onPress,
  }: {
    logoUrl?: string | null;
    name: string;
    onPress: () => void;
  }) => (
    <Button
      variant={"link"}
      className="justify-start p-0 m-0 h-5 space-x-1"
      onPress={onPress}
    >
      {logoUrl && (
        <img src={logoUrl} alt={name} className="h-4 object-contain" />
      )}
      <p
        className="text-xs leading-tight font-normal text-blue-700 underline decoration-blue-500 hover:text-blue-800 hover:decoration-blue-800 transition-colors overflow-hidden whitespace-nowrap text-ellipsis max-w-96 md:max-w-72 lg:max-w-80 xl:max-w-96"
        title={name || "Unknown Publisher"}
      >
        {name || "Unknown Publisher"}
      </p>
    </Button>
  ),
);

PublisherButton.displayName = "PublisherButton";

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  articleId,
  headline,
  overriddenHeadline,
  overriddenArticlePublisher,
  articlePublisher,
  articleDate,
  articleUrl,
  handleHeadlineClick,
  onHeadlineChange,
}) => {
  const [currentPublisher, setCurrentPublisher] = useState(
    overriddenArticlePublisher ?? articlePublisher,
  );
  const [editedHeadline, setEditedHeadline] = useState(
    overriddenHeadline ?? headline,
  );
  const [editedDate, setEditedDate] = useState(articleDate);

  const [isEditingPublisher, setIsEditingPublisher] = useState(false);
  const { publishers, isLoading, handleSearch } = usePublisherSearch();
  const memoizedHandleSearch = useCallback(handleSearch, [handleSearch]);
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef(null);

  const handleArticleOpen = useCallback(() => {
    if (!articleUrl) return;
    window.open(articleUrl, "_blank", "noopener,noreferrer");
  }, [articleUrl]);

  const handleHeadlineSave = useCallback(
    (updatedHeadline: string) => {
      if (updatedHeadline.trim().length < 1) {
        return false;
      }

      onHeadlineChange?.(updatedHeadline);
      setEditedHeadline(updatedHeadline);
      return true;
    },
    [onHeadlineChange],
  );

  const handleOnSaveDate = useCallback(
    async (date: Date) => {
      const newDate = date.toISOString();
      if (newDate === articleDate) return;
      const result = await updateArticle({
        articleId,
        datePublished: newDate,
      });
      if (!result) {
        toast.error("Failed to update date.");
      } else {
        setEditedDate(newDate);
      }
    },
    [articleDate, articleId],
  );

  const handlePublisherSelect = useCallback(
    async (publisherId: string) => {
      const selectedPublisher = publishers.find((p) => p.id === publisherId);
      if (!selectedPublisher) return;
      const publisher = {
        id: selectedPublisher.id,
        name: selectedPublisher.name,
        url: selectedPublisher.url,
        logoUrl: selectedPublisher.logos[0]?.image?.url ?? "",
      };
      const result = await updateArticle({
        articleId,
        publisher,
      });
      if (result) {
        setIsEditingPublisher(false);
        setCurrentPublisher({
          ...publisher,
          id: +publisher.id,
        });
      } else {
        toast.error("Failed to update publisher");
      }
    },
    [articleId, publishers],
  );

  const selectedPublishers = useMemo(
    () => ({
      selected: currentPublisher
        ? [
            {
              id: String(currentPublisher.id),
              name: currentPublisher.name,
              url: currentPublisher.url || "",
              logos: [
                {
                  image: {
                    url: currentPublisher.logoUrl || "",
                  },
                },
              ],
            },
          ]
        : [],
    }),
    [currentPublisher],
  );

  const handleEditClick = useCallback(() => {
    setIsEditingPublisher(true);
    setIsHovered(false);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditingPublisher(false);
  }, []);

  const memoizedFormattedDate = useMemo(
    () => (editedDate ? formattedDateToUTC(editedDate) : undefined),
    [editedDate],
  );

  return (
    <div className="flex gap-y-2 flex-col relative -mt-1">
      <div>
        <EditableDate
          initialDate={memoizedFormattedDate}
          onSave={handleOnSaveDate}
        />
        <EditableField
          fieldName="Headline"
          initialFieldValue={editedHeadline}
          onSave={handleHeadlineSave}
          maxLines={3}
          className="text-xl text-left font-serif pr-5 overflow-hidden leading-tight text-slate-700 line-clamp-3 underline-offset-2 hover:underline hover:cursor-pointer resize-none"
          nonHoverClick={handleHeadlineClick}
          key={editedHeadline}
        />
      </div>
      {isEditingPublisher ? (
        <div className="w-[342px] -my-0.5 -mx-1">
          <PublisherSearchDropdown
            publishers={publishers}
            isLoading={isLoading}
            onSearch={memoizedHandleSearch}
            onSelect={handlePublisherSelect}
            selectedPublishers={selectedPublishers}
            multiSelect={false}
            className="h-8"
            autoOpen={true}
            cancelEdit={handleCancelEdit}
          />
        </div>
      ) : (
        <div
          className={cn(
            "space-y-1 relative group items-center w-fit -my-0.5 -mx-2 py-0.5 px-2 rounded pr-8",
            isHovered ? "bg-slate-200/20" : "",
          )}
        >
          <EditButton
            ref={triggerRef}
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-1.5 right-1"
          />
          <PublisherButton
            logoUrl={currentPublisher?.logoUrl}
            name={currentPublisher?.name || "Unknown Publisher"}
            onPress={handleArticleOpen}
          />
        </div>
      )}
    </div>
  );
};

ArticleHeader.displayName = "ArticleHeader";

export default ArticleHeader;
