import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/data-display/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { cn } from "@/lib/utils";
import { Plus, X } from "@phosphor-icons/react";
import { memo } from "react";

interface FilterPillProps {
  title?: string;
  selectedOptions?: string[];
  setSelectedOptions: (options: string[] | undefined) => void;
  advanced?: boolean;
}

function FilterPill({
  title,
  selectedOptions,
  setSelectedOptions,
}: FilterPillProps) {
  const selectedValues = new Set(selectedOptions);

  const renderBadge = () => {
    const selectedArray = Array.from(selectedValues);
    if (selectedArray.length > 3) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="first-letter:uppercase whitespace-nowrap inline-flex items-center">
                {selectedArray.length} selected
              </div>
            </TooltipTrigger>
            <TooltipContent className="max-w-md text-wrap z-40">
              {selectedArray
                .map((value) => {
                  const option = selectedOptions?.find(
                    (option) => option === value,
                  );
                  return option || value;
                })
                .filter(Boolean)
                .join(", ")}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    const validOptions = selectedArray
      .map((value) => selectedOptions?.find((option) => option === value))
      .filter(Boolean) as string[];

    return (
      <span className="first-letter:uppercase whitespace-nowrap">
        {validOptions.length === 1
          ? validOptions[0]
          : validOptions.length === 2
            ? validOptions.join(" or ")
            : `${validOptions.slice(0, -1).join(", ")}, or ${validOptions.slice(-1)}`}
      </span>
    );
  };

  if (selectedValues.size === 0) return null;

  return (
    <div className="relative">
      <div
        data-filter-pill
        className="inline-flex items-center leading-tight h-[30px] rounded-3xl border border-gray-200 text-slate-800 text-xs px-3 py-[7px]"
      >
        {selectedValues.size === 0 && (
          <Plus className="mr-1 h-4 w-4 text-slate-500" />
        )}
        <span className="pr-1 font-normal">
          {title}
          {selectedValues.size > 0 && ":"}
        </span>

        {selectedValues.size > 0 && (
          <>
            <Badge
              status={false}
              className="rounded-sm px-1 font-normal lg:hidden"
            >
              {selectedValues.size}
            </Badge>
            <div className="hidden space-x-1 lg:flex pr-5">{renderBadge()}</div>
          </>
        )}
      </div>
      {selectedOptions && selectedOptions.length > 0 && (
        <Button
          size="none"
          variant="ghost"
          onPress={() => {
            setSelectedOptions(undefined);
          }}
          className="group py-0.5 pl-px pr-1.5 transition duration-300 absolute right-px inset-y-px rounded-r-3xl hover:bg-transparent data-[pressed=true]:bg-gray-100 data-[focus-visible]:ring-0 border-0"
        >
          {({ isFocused }) => (
            <span
              className={cn(
                "p-[3px] border border-transparent rounded",
                isFocused && "border-blue-200",
              )}
            >
              <X
                className="text-gray-600 hover:text-gray-900 transition duration-300 "
                size={14}
              />
            </span>
          )}
        </Button>
      )}
    </div>
  );
}

export default memo(FilterPill);
