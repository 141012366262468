import FeedVolumeGraphHeader from "@/components/news/feed/FeedVolumeGraph/FeedVolumeGraphHeader";
import BarChart from "@/components/ui/bar-chart/BarChart";
import type { BarChartEventProps } from "@/components/ui/bar-chart/BarChart.types";
import {
  ChartSkeleton,
  NoDataView,
} from "@/components/ui/bar-chart/ChartPlaceholders";
import { addCommasToNumbers } from "@/lib/utils/prettyName";
import {
  setFeedVolumeSelectedDate,
  setPeriod,
} from "@/store/news/feed.actions";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { useState } from "react";
import FeedVolumeGraphTooltip from "./FeedVolumeGraphTooltip";
import {
  type StackByKey,
  getColorsForStackBy,
  getFormatterForPeriod,
  useGetFeedVolume,
} from "./useFeedVolumeGraphData";

type VolumeDataPeriod = {
  categoryClicked: string; // "volume"
  endOfPeriod: string; // ISO 8601 datetime string
  eventType: string; // "bar"
  startOfPeriod: string; // ISO 8601 datetime string
  volume: number; // numeric value
  __typename: string;
};

const FeedVolumeGraph = () => {
  const {
    feedVolume,
    fetching: isFetchingFeedVolume,
    stackByLabels,
  } = useGetFeedVolume();
  const [setFeedVolume, stackFeedVolumeBy, setStackFeedVolumeBy] = useFeedStore(
    (state) => [
      state.setFeedVolume,
      state.stackFeedVolumeBy,
      state.setStackFeedVolumeBy,
    ],
  );

  const [period, filterDays] = useFeedParamsStore((state) => [
    state.period,
    state.filterDays,
  ]);

  const [activeBar, setActiveBar] = useState<undefined | any>(undefined);

  const resetView = () => {
    setFeedVolume(null);
    setFeedVolumeSelectedDate(null);
    setActiveBar(undefined);
  };

  const stackBy = stackFeedVolumeBy
    ? stackFeedVolumeBy === "sentiment"
      ? ["Negative", "Neutral", "Positive"]
      : stackByLabels.toReversed()
    : ["volume"];

  const handleBarClick = (v: BarChartEventProps) => {
    const { eventType, categoryClicked, ...rest } = v || {};

    setFeedVolume(
      v ? (v.volume as unknown as VolumeDataPeriod["volume"]) : null,
    );
    setActiveBar(v ? rest : undefined);
    setFeedVolumeSelectedDate(
      v
        ? {
            startDate: v.startOfPeriod as VolumeDataPeriod["startOfPeriod"],
            endDate: v.endOfPeriod as VolumeDataPeriod["endOfPeriod"],
          }
        : null,
    );
  };

  const renderDataView = () => {
    if (isFetchingFeedVolume)
      return (
        <ChartSkeleton
          className={
            "lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pb-7 pt-2"
          }
        />
      );

    if (!feedVolume || (feedVolume.length === 0 && !isFetchingFeedVolume)) {
      return <NoDataView className={"top-[-20px]"} />;
    }

    return (
      <BarChart
        key={stackFeedVolumeBy ?? "default"}
        className="h-80"
        data={feedVolume}
        index={"startOfPeriod"}
        categories={stackBy}
        onValueChange={(v) => handleBarClick(v)}
        colors={getColorsForStackBy(
          stackFeedVolumeBy as StackByKey,
        )?.toReversed()}
        borderRadius={2}
        showLegend={!!stackFeedVolumeBy}
        intervalType={filterDays === 90 && period === "day" ? 3 : 0}
        formatXAxisTick={(_v, index) => {
          const item = feedVolume[index];

          return getFormatterForPeriod(period, {
            startDate: item?.startOfPeriod,
            endDate: item?.endOfPeriod,
          });
        }}
        valueFormatter={(v) => (v ? addCommasToNumbers(v) : "0")}
        rotateXAxisTick={feedVolume.length > 12}
        defaultActiveBar={activeBar}
        mode={stackFeedVolumeBy ? "solid" : "gradient"}
        type={stackFeedVolumeBy ? "stacked" : "default"}
        customTooltip={(props) => {
          return (
            <FeedVolumeGraphTooltip
              {...props}
              stackedBy={stackFeedVolumeBy}
              label={props.label}
              period={period}
            />
          );
        }}
      />
    );
  };

  return (
    <div
      className={"lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pb-7 pt-2"}
    >
      <div className={"flex justify-end"}>
        <FeedVolumeGraphHeader
          period={period}
          resetView={resetView}
          setPeriod={setPeriod}
          stackFeedVolumeBy={stackFeedVolumeBy}
          setStackFeedVolumeBy={setStackFeedVolumeBy}
        />
      </div>
      {renderDataView()}
    </div>
  );
};

export default FeedVolumeGraph;
