import { DropdownButton } from "@/components/ui/button";
import { Menu, MenuItem, MenuPopover, MenuTrigger } from "@/components/ui/menu";
import type { OptionElementType } from "@/types/shared";
import { Calendar } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

const dayOptions: OptionElementType<string>[] = [
  {
    label: "Last 30 days",
    icon: <Calendar className="w-5 h-5" />,
    value: "30",
  },
  {
    label: "Last 90 days",
    icon: <Calendar className="w-5 h-5" />,
    value: "90",
  },
  { label: "Last 7 days", icon: <Calendar className="w-5 h-5" />, value: "7" },
  { label: "Last 3 days", icon: <Calendar className="w-5 h-5" />, value: "3" },
  { label: "Last 1 day", icon: <Calendar className="w-5 h-5" />, value: "1" },
];

interface FilterDaysProps {
  filterDays: string;
  updateDateRange: (value: number) => void;
}

export const FilterDays = ({
  filterDays,
  updateDateRange,
}: FilterDaysProps) => {
  const [selectedOption, setSelectedOption] = useState(
    dayOptions.find((option) => option.value === `${filterDays}`),
  );

  useEffect(() => {
    if (!selectedOption || selectedOption.value !== `${filterDays}`) {
      setSelectedOption(
        dayOptions.find((option) => option.value === `${filterDays}`),
      );
    }
  }, [filterDays, selectedOption]);

  return (
    <MenuTrigger>
      <DropdownButton
        ariaLabel="Filter by days"
        icon={selectedOption?.icon}
        label="Last 30 days"
        customLabel={selectedOption?.label}
      />
      <MenuPopover>
        <Menu className="bg-white">
          {dayOptions.map((option) => (
            <MenuItem
              key={option.value}
              onAction={() => {
                updateDateRange(+option.value);
                setSelectedOption(option);
              }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </MenuPopover>
    </MenuTrigger>
  );
};
