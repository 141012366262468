import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { memo } from "react";
import FilterComponentItem from "../FilterComponentItem";

interface FilterListProps {
  items: DerivedFilterItem[];
  isOpen: boolean;
  handleFilterChange: (filter: DerivedFilterItem) => void;
  handleEditClick?: (tier: string) => Promise<void>;
  editKnownTag?: (tags: string[]) => void;
  filters: DerivedFilterItem[];
  addEditFilter: (updatedValue: string, filterId?: string) => void;
  showMore?: boolean;
}

function FilterList({
  items,
  isOpen,
  handleFilterChange,
  handleEditClick,
  editKnownTag,
  filters,
  addEditFilter,
  showMore = false,
}: FilterListProps) {
  // Display all items when showMore is true, otherwise only show first 10
  const displayItems = showMore ? items : items.slice(0, 10);

  return (
    <div className={`space-y-0.5 ${showMore ? "overflow-y-auto" : ""}`}>
      {displayItems.map((filter) => (
        <FilterComponentItem
          key={filter.id}
          title={filter.value}
          count={filter.count}
          selected={filter.selected}
          toggleFilter={() => handleFilterChange(filter)}
          tabIndex={isOpen ? 0 : -1}
          handleEditClick={handleEditClick}
          confirmValue={(update) => addEditFilter(update, filter.id)}
          confirmRemove={
            editKnownTag
              ? () =>
                  editKnownTag(
                    filters
                      .filter((fil) => fil.id === filter.id)
                      .map((fil) => fil.value),
                  )
              : undefined
          }
        />
      ))}
    </div>
  );
}

export default memo(FilterList);
