import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PendoTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPage = () => {
      try {
        window.pendo?.trackPage?.(location.pathname);
      } catch (error) {
        console.error("Pendo page tracking error:", error);
      }
    };

    // Use microtask to defer tracking
    queueMicrotask(trackPage);
  }, [location.pathname]);

  return null;
};
