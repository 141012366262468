import { SplitButtonDropdown } from "@/components/ui/molecules/SplitButtonDropdown";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import { FileCsv, Newspaper, NewspaperClipping } from "@phosphor-icons/react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AddArticleURLModal from "./modals/AddArticleURLModal";
import UploadCSVModal from "./modals/UploadCSVModal";

const MODAL_PARAM = "dialog" as const;
const ADD_ARTICLE_URL_VALUE = "add-article-url" as const;
const UPLOAD_CSV_VALUE = "upload-csv" as const;

const FeedAddDropdown = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isURLModalOpen, setIsURLModalOpen] = useState(
    searchParams.get(MODAL_PARAM) === ADD_ARTICLE_URL_VALUE,
  );
  const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState(
    searchParams.get(MODAL_PARAM) === UPLOAD_CSV_VALUE,
  );

  const handleOpenAddArticleURLModal = () => {
    setIsURLModalOpen(true);
    setSearchParams(
      (prev) => {
        prev.set(MODAL_PARAM, ADD_ARTICLE_URL_VALUE);
        return prev;
      },
      { replace: true },
    );
  };

  const handleOpenUploadCSVModal = () => {
    setIsUploadCSVModalOpen(true);
    setSearchParams(
      (prev) => {
        prev.set(MODAL_PARAM, UPLOAD_CSV_VALUE);
        return prev;
      },
      { replace: true },
    );
  };

  const openAddPanel = useFeedPanelStore((state) => state.openAddPanel);

  const dropdownSections = [
    {
      id: "scratch-section",
      label: "Start from scratch",
      options: [
        {
          id: "add-article",
          label: "Add an article",
          icon: Newspaper,
          onClick: handleOpenAddArticleURLModal,
        },
        {
          id: "new-newsfeed",
          label: "Create a new newsfeed",
          icon: NewspaperClipping,
          onClick: openAddPanel,
        },
      ],
    },
    {
      id: "add-section",
      label: "Add from other sources",
      options: [
        {
          id: "csv-upload",
          label: "Upload a CSV file",
          icon: FileCsv,
          onClick: handleOpenUploadCSVModal,
        },
      ],
    },
  ];

  return (
    <>
      <SplitButtonDropdown
        buttonText="Add or upload"
        sections={dropdownSections}
      />
      <AddArticleURLModal
        open={isURLModalOpen}
        onClose={() => setIsURLModalOpen(false)}
      />
      <UploadCSVModal
        open={isUploadCSVModalOpen}
        onClose={() => setIsUploadCSVModalOpen(false)}
      />
    </>
  );
};

export default FeedAddDropdown;
