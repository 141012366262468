import { Skeleton } from "@/components/ui/feedback/skeleton";
import { cn } from "@/lib/utils";
import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { memo, useCallback } from "react";
import AddNewFilter from "../filters/AddNewFilter";
import FilterHeader from "../filters/FilterHeader";
import SearchBar from "../filters/FilterSearchBar";
import ShowMoreButton from "../filters/ShowMoreButton";
import {
  MAX_HEIGHT,
  SEARCH_HEIGHT,
  useFilterHeight,
  useFilterVisibility,
} from "../hooks/useFilterHeight";
import { useFilterSorting } from "../hooks/useFilterSorting";
import { useFilterState } from "../hooks/useFilterState";
import FilterList from "./FilterList";

interface FilterContainerProps {
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  editKnownTag?: (tags: string[]) => void;
  handleEditClick?: (tier: string) => Promise<void>;
}

function FilterContainer({
  title,
  filters,
  filterByTags,
  loading,
  sorted,
  editKnownTag,
  handleEditClick,
}: FilterContainerProps) {
  const {
    searchTerm,
    isOpen,
    showMore,
    displayAddTopic,
    isTransitioning,
    handleFilterChange,
    handleSearchChange,
    handleClearSearch,
    toggleOpen,
    toggleShowMore,
    toggleAddTopic,
    setIsTransitioning,
  } = useFilterState(filters, filterByTags);

  const filteredFilters = useFilterSorting(filters, searchTerm, sorted, title);

  const { needsShowMore, showSearch: shouldShowSearch } = useFilterVisibility(
    filteredFilters.length,
    filters.length,
    !!searchTerm,
  );

  const containerHeight = useFilterHeight({
    isOpen,
    showSearch: shouldShowSearch,
    showMore,
    needsShowMore,
    filteredCount: filteredFilters.length,
  });

  const addEditFilter = useCallback(
    (updatedValue: string, filterId?: string) => {
      if (editKnownTag) {
        const updatedFilters = filterId
          ? filters.map((fil, index) => {
              if (String(index) === filterId) return updatedValue;
              return fil.value;
            })
          : [...filters.map((fil) => fil.value), updatedValue];

        editKnownTag(updatedFilters);
      }
    },
    [editKnownTag, filters],
  );

  const searchBarHeight = shouldShowSearch ? SEARCH_HEIGHT : 0;
  const listHeight = showMore
    ? MAX_HEIGHT - searchBarHeight
    : containerHeight - searchBarHeight;
  return (
    <div className="w-full my-3 first-of-type:mt-2 pl-0.5 relative contain-layout">
      <FilterHeader
        title={title}
        isOpen={isOpen}
        onToggle={() => {
          setIsTransitioning(true);
          toggleOpen();
        }}
        length={loading ? 0 : filters.length}
        loading={loading}
      />
      <div
        className={cn(
          "transition-[height,opacity] duration-200 ease-in-out overflow-hidden contain-layout will-change-[height,opacity] backface-visibility-hidden",
          isOpen && !isTransitioning ? "opacity-100" : "opacity-0",
        )}
        style={{
          height: isOpen ? (showMore ? MAX_HEIGHT : containerHeight) : 0,
        }}
        onTransitionEnd={() => {
          setIsTransitioning(false);
        }}
      >
        {shouldShowSearch && !loading && (
          <div className="sticky top-0 bg-white z-10 contain-layout">
            <SearchBar
              searchTerm={searchTerm}
              title={title}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch}
              isOpen={isOpen}
            />
          </div>
        )}

        <div
          className={cn(
            showMore ? "overflow-y-auto" : "overflow-hidden",
            "contain-layout will-change-transform backface-visibility-hidden",
            "transform-gpu",
          )}
          style={{
            height: isOpen ? listHeight : 0,
            transform: isOpen ? "translate3d(0,0,0)" : "translate3d(0,0,0)",
          }}
        >
          {loading ? (
            <>
              {Array.from({ length: filters.length || 3 }).map((_, index) => (
                <Skeleton
                  // biome-ignore lint/suspicious/noArrayIndexKey: <this is fine>
                  key={index}
                  className={cn(
                    "h-4 w-[calc(100%-8px)] bg-gray-200/45 ml-2 ",
                    index === 0 ? "mt-1" : "mt-2.5",
                  )}
                />
              ))}
            </>
          ) : (
            <>
              {isOpen && (
                <FilterList
                  items={filteredFilters}
                  isOpen={isOpen}
                  handleFilterChange={handleFilterChange}
                  handleEditClick={handleEditClick}
                  editKnownTag={editKnownTag}
                  filters={filters}
                  addEditFilter={addEditFilter}
                  showMore={showMore}
                />
              )}

              {needsShowMore && isOpen && (
                <ShowMoreButton showMore={showMore} onToggle={toggleShowMore} />
              )}

              {displayAddTopic && editKnownTag && (
                <AddNewFilter
                  onConfirm={(newValue) => {
                    toggleAddTopic();
                    addEditFilter(newValue);
                  }}
                  onCancel={toggleAddTopic}
                />
              )}

              {filteredFilters.length === 0 && searchTerm && (
                <p className="text-sm text-slate-500 pl-2">
                  No {title.toLowerCase()} found
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(FilterContainer);
