import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/field";
import { Input, TextField } from "@/components/ui/textfield";
import toast from "@/components/ui/toast";
import { useAppNavigation } from "@/lib/navigation";
import { cn } from "@/lib/utils";
import { addArticle } from "@/store/news/articles.actions";
import { CircleNotch, X } from "@phosphor-icons/react";
import { type ChangeEvent, useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const MODAL_PARAM = "dialog" as const;

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddArticleURLModal = (props: Props) => {
  const { open, onClose } = props;
  const [inputValue, setInputValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [touched, setTouched] = useState(false);
  const [_, setSearchParams] = useSearchParams();
  const [isInAdding, setIsInAdding] = useState(false);
  const { feedId } = useParams();
  const { navigateToArticlePreview } = useAppNavigation();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      if (invalid) setInvalid(false); // Reset invalid state on change
      if (isEmpty) setIsEmpty(false);
    },
    [invalid],
  );

  const validateUrl = (value: string) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return urlRegex.test(value);
  };

  const onAddArticle = async (closeFn: () => void) => {
    if (!inputValue) {
      setIsEmpty(true);
      setInvalid(false);
      setTouched(false);
      return;
    }

    if (!validateUrl(inputValue)) {
      setIsEmpty(false);
      setInvalid(true);
      setTouched(true); // Mark as touched on submit
      return;
    }

    setIsInAdding(true);
    const data = await addArticle(inputValue);
    setIsInAdding(false);
    const { uiMessage, feedArticle } = data;
    const addedArticleId = feedArticle?.id;
    if (uiMessage?.type === "SUCCESS" && addedArticleId) {
      const isAlreadyInNewsfeed = uiMessage.message.includes("already");
      const messageTitle = isAlreadyInNewsfeed
        ? "Article already in newsfeed"
        : "Added to newsfeed";

      const messageDescription =
        uiMessage.message ||
        (isAlreadyInNewsfeed
          ? "This article is already in your newsfeed."
          : "New article successfully added to newsfeed.");

      toast({
        type: isAlreadyInNewsfeed ? "handraise" : "success",
        title: messageTitle,
        description: (
          <div className="pr-4">
            <p className="text-xs font-normal mb-0">{messageDescription}</p>
          </div>
        ),
      });

      closeFn();
      if (feedId) {
        navigateToArticlePreview(feedId, addedArticleId, {
          from: "add-article-url",
        });
      }
      return;
    }
    if (uiMessage?.type === "FAIL") {
      toast({
        type: "error",
        title: "Unable to add article",
        description: uiMessage.message,
      });
    }
    closeFn();
  };

  const handleCloseModal = () => {
    if (!isInAdding) {
      onClose();
      setInputValue("");
      setIsEmpty(false);
      setInvalid(false);
      setTouched(false);
      setIsInAdding(false);
      setSearchParams(
        (prev) => {
          prev.delete(MODAL_PARAM);
          return prev;
        },
        { replace: true },
      );
    }
  };

  return (
    <DialogOverlay
      isOpen={open}
      onOpenChange={(isOpen) => !isOpen && handleCloseModal()}
    >
      <DialogContent className="min-w-[425px] w-[25vw] max-w-[800px]">
        {({ close }) => (
          <>
            <DialogHeader>
              <DialogTitle>Add an article</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4 relative min-h-[125px]">
              <TextField
                autoFocus
                validate={(value) =>
                  validateUrl(value) ? null : "Invalid link"
                }
                onBlur={() => setTouched(true)}
              >
                <Label>URL/Link*</Label>
                <Input
                  value={inputValue}
                  className={cn(
                    "pr-8",
                    (touched && invalid) || isEmpty || (touched && isEmpty)
                      ? "border-rose-500"
                      : "",
                  )}
                  onChange={handleInputChange}
                  placeholder="https://example.com"
                />
                {touched && invalid && (
                  <p className="text-[10px] text-red-700 mt-2">Invalid link</p>
                )}
                {isEmpty && (
                  <p className="text-[10px] text-red-700 mt-2">Required</p>
                )}
                {inputValue?.length > 0 && !isInAdding && (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <span
                    className="absolute top-14 right-3 w-4 h-4 bg-slate-300 rounded-full p-0.5 cursor-pointer"
                    onClick={() => setInputValue("")}
                  >
                    <X className="text-white w-3 h-3" />
                  </span>
                )}
              </TextField>
            </div>
            <DialogFooter className="">
              <div className="flex w-full sm:flex-row-reverse gap-3">
                <Button
                  onPress={() => onAddArticle(close)}
                  type="submit"
                  isDisabled={isInAdding}
                >
                  {isInAdding ? (
                    <>
                      <span className="mr-2">
                        <CircleNotch className="animate-spin" />
                      </span>
                      <span>Adding</span>
                    </>
                  ) : (
                    <span>Add Article</span>
                  )}
                </Button>
                <Button onPress={handleCloseModal} variant="outline">
                  <span>Cancel</span>
                </Button>
              </div>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};

export default AddArticleURLModal;
