import { cn } from "@/lib/utils";
import { CaretDown } from "@phosphor-icons/react";
import { memo } from "react";

interface FilterHeaderProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  length: number;
  loading: boolean;
}

// List of filters that should show count
const filtersWithCount = [
  "Publications",
  "Themes",
  "Top Topics",
  "People",
  "Authors",
  "Initiatives",
  "Events",
  "Topics",
] as const;

// Base container styles
const containerStyles = cn(
  // Typography
  "text-sm text-gray-800",

  // Layout
  "flex items-start gap-1 h-6 overflow-hidden",
  "min-w-[125px] pl-2",
  "contain-layout",

  // Focus styles
  "ring-offset-white",
  "focus-visible:ring-offset-2 focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:outline-none",

  // Responsive
  "md:block", // Show on desktop
  "hidden", // Hide on mobile
);

// Title text styles
const titleStyles = cn(
  "inline-block min-w-[125px]",
  "overflow-hidden text-ellipsis whitespace-nowrap",
);

const titleTextStyles = "font-medium";
const countStyles = "font-normal";

const caretButtonStyles = cn(
  "absolute -right-1 top-0 h-5 pr-3 flex items-center justify-end",
  "bg-gradient-to-r from-transparent via-white to-white cursor-pointer will-change-transform contain-layout",
);

const FilterHeader = memo(
  ({ title, isOpen, onToggle, length, loading }: FilterHeaderProps) => {
    // Determine if count should be shown based on filter title
    const getTitleAndCount = () => {
      if (
        !filtersWithCount.includes(title as (typeof filtersWithCount)[number])
      ) {
        return <span className={titleTextStyles}>{title}</span>;
      }
      return (
        <>
          <span className={titleTextStyles}>{title}</span>
          {!loading && <span className={countStyles}>{` (${length})`}</span>}
        </>
      );
    };

    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: <click event exists on the button>
      <div
        // biome-ignore lint/a11y/useSemanticElements: <this is needed for the interaction>
        role="button"
        tabIndex={-1}
        onClick={onToggle}
        className={containerStyles}
      >
        <span className={titleStyles}>{getTitleAndCount()}</span>

        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <the header handles the toggle function> */}
        <div className={caretButtonStyles} onClick={onToggle}>
          <CaretDown
            size={16}
            onClick={onToggle}
            className={
              isOpen
                ? "rotate-180 transition-transform transform-gpu will-change-transform"
                : "transition-transform transform-gpu will-change-transform"
            }
          />
        </div>
      </div>
    );
  },
);

FilterHeader.displayName = "FilterHeader";

export default FilterHeader;
