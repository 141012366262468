import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export const MIN_ITEMS_FOR_SEARCH = 11;
export const ITEMS_TO_SHOW = 10;

export function useFilterState(
  filters: DerivedFilterItem[],
  filterByTags: (tags: string[]) => void,
) {
  const selectedFiltersRef = useRef<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [displayAddTopic, setDisplayAddTopic] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const showSearch = filters.length > MIN_ITEMS_FOR_SEARCH;

  useLayoutEffect(() => {
    selectedFiltersRef.current = new Set(
      filters.filter((option) => option.selected).map((option) => option.id),
    );
  }, [filters]);

  // Create a Map for O(1) lookups of filter values by ID
  const filterValuesMapRef = useRef<Map<string, string> | undefined>(undefined);

  // Initialize or update the filter values map
  useLayoutEffect(() => {
    filterValuesMapRef.current = new Map(
      filters.map((filter) => [filter.id, filter.value]),
    );
  }, [filters]);

  const handleFilterChange = useCallback(
    (filterItem: DerivedFilterItem) => {
      const wasSelected = selectedFiltersRef.current.has(filterItem.id);

      selectedFiltersRef.current[wasSelected ? "delete" : "add"](filterItem.id);

      // Get selected values using the Map for O(1) lookups
      const selectedValues = Array.from(
        selectedFiltersRef.current,
        (id) => filterValuesMapRef.current?.get(id) ?? "",
      );

      filterByTags(selectedValues);
    },
    [filterByTags],
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      setShowMore(false);
    },
    [],
  );

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    setShowMore(false);
  }, []);

  const toggleOpen = useCallback(() => {
    setIsTransitioning(true);
    setIsOpen((prev) => !prev);
    // Reset showMore when closing
    if (isOpen) {
      setShowMore(false);
    }
  }, [isOpen]);

  const toggleShowMore = useCallback(() => {
    setShowMore((prev) => !prev);
  }, []);

  const toggleAddTopic = useCallback(() => {
    setDisplayAddTopic((prev) => !prev);
  }, []);

  return {
    searchTerm,
    isOpen,
    showMore,
    displayAddTopic,
    isTransitioning,
    showSearch,
    handleFilterChange,
    handleSearchChange,
    handleClearSearch,
    toggleOpen,
    toggleShowMore,
    toggleAddTopic,
    setIsTransitioning,
  };
}
