import { type StateStorage, createJSONStorage } from "zustand/middleware";

const createUrlStorage = <T>() => {
  const urlStorage: StateStorage = {
    getItem: (): string | null => {
      // Return null at root level to use default state
      if (window.location.pathname === "/newsfeeds") {
        return null;
      }

      const searchParams = new URLSearchParams(window.location.search);
      if (!searchParams.toString()) {
        return null; // Return null if no URL params, allowing default state to be used
      }

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const state: Record<string, any> = {
        selected: {},
      };

      // First collect all selected parameters
      for (const [paramKey, value] of searchParams.entries()) {
        if (paramKey.startsWith("selected.")) {
          const selectKey = paramKey.replace("selected.", "");
          // Always make selected values an array
          state.selected[selectKey] = value ? value.split(",") : [];
          continue;
        }

        // Handle non-selected parameters
        if (value === "") state[paramKey] = "";
        else if (value === "true") state[paramKey] = true;
        else if (value === "false") state[paramKey] = false;
        else if (value === "null") state[paramKey] = null;
        else if (value !== "" && !Number.isNaN(Number(value)))
          state[paramKey] = Number(value);
        else state[paramKey] = value;
      }

      return JSON.stringify({ state });
    },

    setItem: (_, valueStr): void => {
      try {
        // Don't set params if we're at the root newsfeeds path
        if (window.location.pathname === "/newsfeeds") {
          window.history.replaceState(null, "", window.location.pathname);
          return;
        }

        const { state } = JSON.parse(valueStr);
        const currentParams = new URLSearchParams(window.location.search);
        const newParams = new URLSearchParams();

        // First handle the selected params
        const selectedState = state.selected || {};
        for (const [selectKey, selectValue] of Object.entries(
          selectedState as Record<string, string[]>,
        )) {
          if (Array.isArray(selectValue) && selectValue.length > 0) {
            newParams.set(`selected.${selectKey}`, selectValue.join(","));
          }
        }

        // Then handle all other params
        for (const [key, value] of currentParams.entries()) {
          // Skip selected params as we've already handled them
          if (key.startsWith("selected.")) continue;

          // For non-selected params, keep them if they're not in our state
          if (!(key in state)) {
            newParams.set(key, value);
          }
        }

        // Finally add our managed non-selected params
        for (const [key, value] of Object.entries(state)) {
          if (key !== "selected" && value !== null && value !== undefined) {
            newParams.set(key, String(value));
          }
        }

        const search = newParams.toString();
        const newUrl = search
          ? `${window.location.pathname}?${search}`
          : window.location.pathname;

        // Only update if the URL would actually change
        if (window.location.href !== newUrl) {
          window.history.replaceState(null, "", newUrl);
        }
      } catch (error) {
        console.error("Error updating URL storage:", error);
      }
    },

    removeItem: (): void => {
      window.history.replaceState(null, "", window.location.pathname);
    },
  };

  return createJSONStorage<T>(() => urlStorage);
};

export default createUrlStorage;
