import { Button } from "@/components/ui/controls/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { Checkbox } from "@/components/ui/forms/checkbox";
import { cn } from "@/lib/utils";
import { Check, Trash } from "@phosphor-icons/react";
import {
  type FC,
  type HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface MemoizedTooltipProps {
  filterValue: string;
  labelRef: React.RefObject<HTMLLabelElement | null>;
  handleEditClick?: (tier: string) => Promise<void>;
  canEdit: boolean;
  tabIndex: number;
  setIsEditing: (value: boolean) => void;
  title: string;
}

const MemoizedTooltip: React.FC<MemoizedTooltipProps> = memo(
  ({
    filterValue,
    labelRef,
    handleEditClick,
    canEdit,
    tabIndex,
    setIsEditing,
    title,
  }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <label
            ref={labelRef}
            htmlFor={filterValue}
            className="relative ml-0 text-xs flex-1 min-w-0 font-normal text-gray-700 overflow-hidden whitespace-nowrap text-ellipsis pr-2.5 cursor-pointer"
          >
            <span className="text-slate-700 block truncate">{filterValue}</span>
            {canEdit && (
              <Button
                className={cn(
                  "ml-2 h-5 px-1 py-0 text-xs text-slate-700 bg-white border-none shadow-sm hover:bg-white-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                  "absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity will-change-[opacity,transform]",
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                tabIndex={tabIndex}
              >
                Edit
              </Button>
            )}
            {handleEditClick && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(title);
                }}
                tabIndex={tabIndex}
                className="absolute right-0 top-1/2 -translate-y-1/2 bg-white text-sm font-normal text-slate-700 ml-2 py-0 px-2 rounded-md opacity-0 group-hover:opacity-100 transition-opacity will-change-[opacity,transform]"
              >
                Edit
              </button>
            )}
          </label>
        </TooltipTrigger>
        <TooltipContent>
          <p title={filterValue}>{filterValue}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ),
);

export interface FilterItemProps {
  title: string;
  count: number;
  selected: boolean;
  tabIndex: number;
  toggleFilter: () => void;
  handleEditClick?: (tier: string) => Promise<void>;
  confirmValue?: (updatedValue: string) => void;
  confirmRemove?: () => void;
}

const FilterComponentItem: FC<FilterItemProps & HTMLAttributes<HTMLElement>> =
  memo(
    ({
      title,
      count,
      selected,
      tabIndex,
      toggleFilter,
      handleEditClick,
      confirmValue,
      confirmRemove,
    }) => {
      const [state, setState] = useState({
        isEditing: false,
        filterValue: title,
        confirmedValue: title,
      });

      const { isEditing, filterValue, confirmedValue } = state;
      const inputRef = useRef<HTMLInputElement>(null);
      const labelRef = useRef<HTMLLabelElement>(null);

      const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          setState((prev) => ({ ...prev, confirmedValue: e.target.value }));
        },
        [],
      );

      const removeTopTopic = useCallback(() => {
        setState((prev) => ({ ...prev, isEditing: false }));
        if (!confirmRemove) return;
        confirmRemove();
      }, [confirmRemove]);

      const setIsEditing = useCallback((value: boolean) => {
        setState((prev) => ({ ...prev, isEditing: value }));
      }, []);

      const handleConfirmEditing = useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          setState((prev) => ({
            ...prev,
            isEditing: false,
            filterValue: prev.confirmedValue,
          }));
          e.preventDefault();
          e.stopPropagation();

          if (!confirmValue) return;
          confirmValue(confirmedValue);
        },
        [confirmValue, confirmedValue],
      );

      const handleKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            setState((prev) => ({
              ...prev,
              isEditing: false,
              filterValue: prev.confirmedValue,
            }));
            if (!confirmValue) return;
            confirmValue(confirmedValue);
          } else if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            setState((prev) => ({ ...prev, isEditing: false }));
          }
        },
        [confirmValue, confirmedValue],
      );

      useEffect(() => {
        if (isEditing && inputRef.current) {
          setTimeout(() => {
            inputRef.current?.focus();
          }, 300);
        }
      }, [isEditing]);

      const canEdit = Boolean(confirmValue) && Boolean(confirmRemove);

      return (
        <li
          key={filterValue}
          className="group flex items-center hover:bg-slate-300/10 rounded-md pl-1 pr-2 py-1 cursor-pointer ml-1 will-change-[background-color] transition-colors contain-layout"
          onClick={(event) => {
            // Prevent double selection by checking if click originated from checkbox or its label
            const target = event.target as HTMLElement;
            if (
              target instanceof HTMLInputElement ||
              target instanceof HTMLLabelElement
            ) {
              return;
            }
            toggleFilter();
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              event.preventDefault();
              toggleFilter();
            }
          }}
        >
          <div className="flex items-center flex-1 min-w-0 space-x-1.5">
            <Checkbox
              checked={selected}
              tabIndex={tabIndex}
              variant={"secondary"}
            />
            {!isEditing && (
              <MemoizedTooltip
                filterValue={filterValue}
                labelRef={labelRef}
                handleEditClick={handleEditClick}
                canEdit={canEdit}
                tabIndex={tabIndex}
                setIsEditing={setIsEditing}
                title={title}
              />
            )}
            {isEditing && (
              <div className="flex-1 min-w-0 relative">
                <input
                  type="text"
                  className="w-full text-sm border rounded-md px-2 pr-5 py-1 border-slate-200 text-slate-700 focus-visible:outline-none focus-visible:ring-1"
                  value={confirmedValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  onClick={(e) => e.stopPropagation()}
                  ref={inputRef}
                />
                <Check
                  size={16}
                  className="text-slate-600 absolute right-1 top-1/2 -translate-y-1/2 z-10 cursor-pointer"
                  onClick={handleConfirmEditing}
                />
              </div>
            )}
          </div>
          {isEditing ? (
            <Trash
              size={16}
              className="text-slate-600 ml-2 flex-shrink-0"
              onClick={(e) => {
                e.stopPropagation();
                removeTopTopic();
              }}
            />
          ) : (
            <div
              className={cn(
                "text-xs w-12 text-right ml-2 flex-shrink-0",
                count ? "text-slate-500" : "text-slate-500",
              )}
            >
              {count.toLocaleString()}
            </div>
          )}
        </li>
      );
    },
  );

export default FilterComponentItem;
