import { resetFeed, setFeedId } from "@/store/news/feed.actions";
import useFeedStore from "@/store/useFeedStore";
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

const NewsFeedsLayout = () => {
  const { feedId: paramFeedId } = useParams();
  const { feedId: currentFeedId } = useFeedStore();
  const location = useLocation();
  const feedId = paramFeedId ? Number(paramFeedId) : null;

  useEffect(() => {
    if (feedId === null) {
      resetFeed();
    } else if (currentFeedId !== feedId) {
      setFeedId(feedId);
    }
  }, [feedId, location.pathname]);

  return <Outlet />;
};

export default NewsFeedsLayout;
